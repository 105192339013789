.main-slider {
  * {
    box-sizing: border-box;
  }

  .is-multiply {
    mix-blend-mode: multiply;
    opacity: 0.565;
  }

  $main: &;

  #{$main}-container {
    padding: 0 1.25rem;

    #{$main}-wrapper {
      position: relative;
      max-width: 1640px;
      width: 1px;

      @include media(">desktop") {
        margin: 0 80px;
      }

      #{$main}-content {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-bottom: rem-calc(35);

        @media (min-width: 900px) {
          gap: 0px;
          min-height: min(100vh, 700px);
          justify-content: center;
          padding: rem-calc(90) 0;
        }

        @media (min-width: 1024px) {
          min-height: min(100vh, 770px);
          padding: rem-calc(110) 0;
        }

        #{$main}-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: calc(100% - 6px);
          gap: 22px;
          position: relative;
          z-index: 1;

          @media (min-width: 900px) {
            max-width: 500px;
            gap: 30px;
          }

          @media (min-width: 1024px) {
            max-width: 547px;
          }

          &__content {
            display: flex;
            flex-direction: column;
            gap: 12px;

            @media (min-width: 768px) {
              gap: 16px;
            }
          }

          &__description {
            font-size: 12.57px;
            line-height: 1.473;

            @media (min-width: 768px) {
              font-size: 18px;
            }

            @media (min-width: 1024px) {
              font-size: 22px;
            }
          }

          h2 {
            margin: 0;
            color: inherit;
            font-size: 30px;
            line-height: 1.1;
            font-weight: 600;

            @media (min-width: 768px) {
              font-size: 46px;
              font-weight: 700;
            }

            @media (min-width: 1024px) {
              font-size: 58px;
            }
          }

          p {
            margin: 0;
            color: inherit;
          }
        }

        #{$main}-background {
          position: absolute;
          display: block;
          background: center / cover;
          clip-path: polygon(26% 0%, 77% 0%, 100% 50%, 78% 96%, 25% 96%, 0% 47%);
          inset: 24.3% 34% 27% 5%;
          @media(min-width: 900px){
            inset: 0 25% 0 5%;
          }
        }

        #{$main}-image {
          position: relative;
          aspect-ratio: 1203 / 770;
          margin-right: -75%;
          margin-top: -45%;
          margin-bottom: -60%;
          left: 10px;

          @media (min-width: 900px) {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 44%;
            height: 100%;
            max-height: 700px;
            margin: auto 0;
          }

          @media (min-width: 1024px) {
            left: 45%;
          }
        }

        #{$main}-svg {
          position: relative;
          display: block;
          width: 100%;
          // mask-image: linear-gradient(to right, black 55%, #00000000 98%);
          // -webkit-mask-image: linear-gradient(to right, black 55%, #00000000 98%);

          @media (min-width: 900px) {
            position: absolute;
            top: 50%;
            transform: translate(1%, -48%) scale(1.1);
            transform-origin: left center;
          }
          &::after {
            position: absolute;
            content: '';
            height: 40%;
            width: 100%;
            right: 0;
            bottom: 0;
            background: linear-gradient(180deg, transparent, currentColor 75%, currentColor);
            @media (min-width: 900px) {
              height: 100%;
              width: 40%;
              right: 0;
              top: 0;
              background: linear-gradient(90deg, transparent, currentColor 85%, currentColor);
            }
          }
        }
      }
    }
  }

  .ms-arrow {
    $this: &;
    pointer-events: auto;
    background-color: #121c42;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: all ease 0.5s;
    width: 37px;
    aspect-ratio: 1;
    padding: 8px;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $custom-green-alt !important;

      #{$main}-icon {
        color: currentColor !important;
      }
    }

    i svg {
      width: 100%;
      display: block;
    }

    &.swiper-button-disabled {
      opacity: 0;
      pointer-events: none;
    }
  }

  .ms-arrows {
    max-width: 1680px;
    margin: 0 auto;
    padding: 0 20px;
    pointer-events: none;
    gap: 15px;
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    display: none;

    @include media(">desktop") {
      left: 80px;
      right: 80px;
    }

    @media (min-width: 900px) {
      bottom: 60px;
      display: flex;
    }

    @media (min-width: 1024px) {
      bottom: 40px;
    }
  }

  .ms-pagination {
    position: absolute;
    padding: 0 20px;
    bottom: 5px;
    z-index: 1;
    text-align: center;
    @media (min-width: 768px) {
      bottom: 25px;
    }

    @media (min-width: 900px) {
      display: none;
    }

    .swiper-pagination {
      &-bullet {
        box-shadow: 0px 0px 3px 0px rgba(104, 104, 104, 0.8);
        width: 6px;
        height: 6px;
        background-color: white;
        opacity: 1;

        @media (min-width: 480px) {
          width: 8px;
          height: 8px;
        }

        &-active {
          background-color: $custom-green;
        }
      }
    }
  }

  .button {
    gap: 0.5rem;
    padding: 0.799em 1.5em;
    width: 100%;
    height: auto !important;
    border-radius: 3px;
    border: none;
    font-weight: 500;
    font-size: 12.57px;
    line-height: 22.52px;

    @media (min-width: 768px) {
      width: auto;
      padding: 0.75em 1.65em;
      font-size: 18.74px;
      line-height: 32.79px;
    }

    .icon {
      margin: 0 !important;
      padding-top: 0.25rem;
      transition: transform 0.3s ease-in-out;

      &:hover {
        color: currentColor !important;
      }
    }

    &:hover {
      background: $custom-green-alt !important;

      .icon {
        transform: translateX(0.5rem);
      }
    }
  }

  .swiper-slide {
    height: auto;
  }

  :is(#{$main}-wrapper, .swiper-slide) {
    display: flex;
    flex-direction: column;
  }

  :is(#{$main}-container, #{$main}-wrapper, #{$main}-content) {
    flex-grow: 1;
  }

  #{$main}-container {
    display: flex;
    justify-content: center;
  }

  &.is-hero-heading {
    #{$main}-content {
      min-height: auto !important;

      @media(min-width:768px) {
        padding-top: 72px !important;
      }

      padding-bottom: 72px !important;
      #{$main}-image {

        @media (min-width: 900px) {
          left: 44% !important;
          top: 50% !important;
  
          max-height: 650px!important;
        }
        @media (min-width: 1024px) {
          left: 45% !important;
          top: 20% !important;
        }
        @media (min-width: 1600px) {
          left: 34%!important;
          top: 0% !important;
        }
      }

      #{$main}-info {
        h2 {
          font-size: 20px !important;

          @media (min-width: 768px) {
            font-size: 24px !important;
          }

          @media (min-width: 1024px) {
            font-size: 30px !important;
            line-height: 1.4 !important;
          }
        }

        &__description {
          @media (min-width: 1024px) {
            font-size: 20px !important;
          }
        }
      }
    }

    #BreadcrumbsHeading {
      font-size: 16px;
      align-self: center;

      a:hover {
        color: $pink !important;
      }

      @media(max-width: 1023px) {
        padding: 0.625rem !important;
      }
    }

    #SocialMediaHeading {
      margin-left: auto;
      align-self: center;
      margin-bottom: -0.75rem;

      @media(max-width: 1023px) {
        padding: 0.625rem !important;
      }
    }

    #Top {
      position: relative;
      z-index: 10;

      @media(min-width:768px) {
        padding-bottom:28px;
      }
    }

    #InfoHeading {
      padding: 0;
      width: 100%;

      @media(min-width:768px) {
        margin-bottom: 38px;
      }

      h1 {
        margin: 0 0 16px;
        color: inherit;
        font-size: 30px;
        line-height: 1.1;
        font-weight: 600;

        @media (min-width: 768px) {
          font-size: 46px;
          font-weight: 700;
        }

        @media (min-width: 1024px) {
          font-size: 58px;
        }
      }

      p {
        font-size: 12.57px;
        line-height: 1.473;
        font-weight: 400;

        @media (min-width: 768px) {
          font-size: 18px;
        }

        @media (min-width: 1024px) {
          font-size: 20px;
        }
      }
    }

  }
}