.topics-section {
  padding-top: rem-calc(29);
  padding-bottom: rem-calc(38);

  @include media(">=tablet") {
    padding-top: rem-calc(76);
    padding-bottom: rem-calc(72);
  }

  .section-heading {
    @include media(">=desktop") {
      padding-bottom: rem-calc(32);
    }
  }
}

.topics-buttons {
  word-break: break-word;
  align-items: stretch;

  @include media("<tablet") {
    margin-right: rem-calc(-3);
    margin-left: rem-calc(-3);
    width: calc(100% + 6px);
  }

  @include media(">=tablet") {
    flex-wrap: nowrap;
    box-shadow: 0 rem-calc(6) rem-calc(20) rgba($black, 0.15);
    border-radius: $radius-xlarge;
  }

  .button {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;
    width: 100%;
    height: auto !important;
    min-height: rem-calc(94);
    padding: rem-calc(12);
    font-size: 0.7647em; // 13px
    line-height: 1.3077; // 17px
    white-space: normal;
    box-shadow: 0 0 rem-calc(15) rgba($black, 0.1);
    position: relative;

    @include media(">=phone") {
      width: calc(50% - 6px);
    }

    @include media("<tablet") {
      margin: rem-calc(3) !important;
      border-radius: rem-calc(12) !important;
      border: 0;
    }

    @include media(">=tablet") {
      align-items: flex-start;
      width: 25%;
      padding: rem-calc(20 15);
      font-size: 0.9412em; // 16px
      line-height: 1.0952;
      border-width: 0 1px 0 0;
      border-color: #d3d3d3;
      box-shadow: none;
      flex-grow: 1;
      margin: 0 !important;
    }

    @include media(">=desktop") {
      padding: rem-calc(28 25 23);
      font-size: 1.24em; // 21px
    }

    &:first-child {
      @include media(">=tablet") {
        border-bottom-left-radius: $radius-xlarge;
        border-top-left-radius: $radius-xlarge;
      }
    }

    &:last-child {
      @include media(">=tablet") {
        border-right: 0;
        border-bottom-right-radius: $radius-xlarge;
        border-top-right-radius: $radius-xlarge;
      }
    }

    &:hover {
      background-color: $link;

      span {
        color: $white !important;
        * {
          color: $white !important;
        }
      }
    }

    .icon {
      @include size(auto);
      font-size: 0.8462em; // 11px
      line-height: 1;
      margin-left: rem-calc(3) !important; // 3px
      margin-right: rem-calc(-3) !important; // -3px

      @include media(">=tablet") {
        font-size: 0.8095em; // 17px
        line-height: 1;
        top: -1px;
        position: relative;
      }
    }
  }
}

.block_topics_in_focus {
  .topics-buttons {
    a.button {
      &:only-child {
        @include media("<tablet") {
          width: calc(100% - 6px);
        }
      }
    }
  }
}

.second-topic-in-focus {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 14px;
  width: 100%;

  @include media("<tablet") {
    box-shadow: 0px 0px 30px 0px #00000026;
    border: 1px solid #a1c2c1;
    background-color: white;
    padding: 32px 20px 42px;
  }

  @include media(">=desktop") {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
  }

  .section-heading {
    width: 100%;
    @include media(">=desktop") {
      width: clamp(320px, 33%, 520px);
      flex-shrink: 0;
    }
  }

  &-list {
    --col: 2;
    display: grid;
    gap: 0.375rem;
    @include media(">=tablet") {
      grid-template-columns: repeat(var(--col), minmax(0, 1fr));
      gap: 24px 40px;
    }
  }
  &-content {
    width: 100%;
    @include media(">=desktop") {
      width: clamp(calc(50% - 40px), calc(66% - 40px), 100%);
      flex-grow: 1;
      flex-shrink: 0;
    }
  }

  &-item {
    margin: 0;
    padding: 0.6875rem 0 0;
    border-top: 1px solid #b3d2d8;
    line-height: 1.25;
    font-size: 15px;

    @include media(">=desktop") {
      line-height: 1.4;
      font-size: 22px;
      padding: 0.6rem 0 0;
    }

    &::before {
      display: none;
    }
    .second-topic-in-focus-link {
      line-height: inherit;
      font-size: inherit;
      font-weight: 500;
      transition: color 0.3s ease-in-out;

      &::after {
        color: $pink !important;
        transition: transform 0.3s ease-in-out;
        margin-top: 0.125rem;
      }
      &:hover {
        color: $pink-dark !important;
        &::after {
          transform: translateX(0.25rem);
        }
      }
    }
  }
}
