.navigator-highlight {
  &.is-second {
    .button.is-second {
      background-color: #e7f5f3;
      color: $cyan;
      border: 0;

      &:hover {
        background-color: $cyan;
        color: $white !important;
      }
    }

    ul:not(.navigator-highlight__list) a {
      color: #0b5793 !important;

      &:hover {
        color: $pink !important;
      }
    }
  }
}
