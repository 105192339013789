@keyframes skeleton-loading {
  from {background-color: $medium-teal;}
  to {background-color: hsl(178, 32%, 85%);}
}

.skeleton {
  opacity: .4;

  &__item {
    background: $aqua-light !important;
    animation: skeleton-loading 1s infinite alternate;
    display: inline;
    margin: 2px 0;

    &.block {
      display: block;
    }
  }

  img {
    display: block;
    width: 100%;
    height: 200px;
    opacity: 0;
  }

  * {
    color: rgba(white, 0) !important;
    user-select: none;
    cursor: default !important;
  }
}