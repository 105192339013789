.social-media {
	display: flex;
	flex-wrap: wrap;

	@include media(">=desktop") {
		justify-content: flex-end;
	}

	.blockquote & {
		padding-left: rem-calc(50);

		@include media(">=desktop") {
			padding-left: rem-calc(87);
		}
	}

	#footer & {
		display: block;

		@include media(">=desktop") {
			display: flex;
		}
	}

	.page-heading & {

		@include media(">=desktop") {
			padding-top: 0;
		}
	}

	&.share-article-media {
		display: block;
		border-top: 2px solid $primary;
		padding-top: rem-calc(28);

		.content {
			font-size: 0.8235em; // 14px
			line-height: 1.5714; // 22px
			color: $grey;
			margin-bottom: 1.125rem; // 18px
		}

		h5 {
			font-size: 1.1765em; // 20px
			line-height: 1.2; // 24px
			letter-spacing: normal;
			font-weight: $weight-semibold;
			margin-bottom: .7em; // 14px
		}

		&.is-horizontal {

			@include media(">=414px") {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}

			.title-social-media {

				@include media(">=414px") {
					width: 34%;
					padding-right: rem-calc(10);
				}

				@include media(">=desktop") {
					width: 20%;
				}

				@include media(">=fullhd") {
					width: 18%;
				}
			}

			.content {

				@include media(">=414px") {
					width: 66%;
				}

				@include media(">=desktop") {
					width: 28%;
					padding-top: 2px;
				}

				@include media(">=fullhd") {
					width: 34%;
				}
			}
		}
	}

	.title-social-media {
		display: inline-block;
		vertical-align: top;
		font-size: rem-calc(16);
		line-height: 1.25; // 20px
		font-weight: $weight-normal;
		letter-spacing: -.0312em; // -0.5px
		color: $dark;
		white-space: nowrap;
		margin-bottom: rem-calc(12);

		#footer & {
			margin-right: 0;

			@include media(">=desktop") {
				margin-right: rem-calc(14);
			}
		}

		.card &,
		.box &,
		.blockquote &,
		.side-column & {
			font-size: rem-calc(13);
			line-height: 1.5385; // 20px
		}
	}
}

ul.social-networks {
	font-size: rem-calc(20);
	line-height: 1;

	&.social-networks-md {
		font-size: rem-calc(22);

		li {
			margin-left: rem-calc(15);

			&.is-separated {
				padding-left: rem-calc(15);
			}
		}
	}

	.icon:hover {
		color: $pink;
	}

	#footer & {
		margin-left: -0.5rem;
		color: $primary;

		@include media(">=desktop") {
			margin-left: 0;
		}
	}

	.share-article-media & {

		> li {
			margin-left: 0;

			&:not(:last-child) {
				margin-bottom: rem-calc(12);
			}
		}

		a {

			&:hover {

				span {
					color: currentColor;
				}
			}
		}

		.icon {
			margin-right: rem-calc(19);
		}

		span {
			@include animate(color, .15s, ease-out);
			display: inline-block;
			vertical-align: top;
			font-size: rem-calc(15);
			line-height: 1.3333; // 20px
			font-weight: $weight-medium;
			color: $dark;
		}
	}

	.share-article-media.is-horizontal & {

		@include media(">=414px") {
			width: calc(100% + 12px);
			margin-left: rem-calc(-6);
			margin-right: rem-calc(-6);
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding-top: rem-calc(10);
		}

		@include media(">=desktop") {
			margin-left: 0;
			margin-right: 0;
			width: 52%;
			padding-top: 2px;
			justify-content: flex-end;
		}

		@include media(">=fullhd") {
			width: 48%;
		}

		> li {

			@include media(">=414px") {
				margin-left: rem-calc(6);
				margin-right: rem-calc(6);
			}

			@include media(">=desktop") {
				margin-left: rem-calc(13);
				margin-right: 0;
			}

			@include media(">=fullhd") {
				margin-left: rem-calc(25);
			}

			a {
				display: flex;
				align-items: center;
			}
		}

		.icon {

			@include media(">=414px") {
				margin-right: rem-calc(7);
			}
		}

		span {
			font-size: rem-calc(14);
			line-height: 1.4286; // 20px

			@include media(">=widescreen") {
				font-size: rem-calc(15);
				line-height: 1.3333; // 20px
			}
		}
	}

	> li {
		margin: rem-calc(0 0 10 8);

		.card &,
		.box &,
		.blockquote &,
		.side-column & {
			margin-left: rem-calc(16);
		}

		&.is-separated {
			position: relative;
			padding-left: rem-calc(8);

			&:before {
				@include size(1px, rem-calc(15));
				display: block !important;
				content: "";
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				background: currentColor;
				border-radius: 0;
				font-size: 0;
				line-height: 0;
			}
		}
	}

	a {
		color: $dark;

		&:hover {
			color: $link;
		}

		#footer & {
			color: $primary;

			&:hover {
				color: $link;
			}
		}
	}

	.icon {
		vertical-align: top;
		@include size(auto);
	}
}