#header {
	position: sticky;
	top: 0;
	z-index: 999;
	pointer-events: none;
	height: 76px;
	margin-bottom: -3rem;

	@media(min-width: 768px) {
		height: 152px;
	}

	.container {
		position: static;
		max-width: 1640px;
	}

	.second-navbar {
		.container {
			@include media(">desktop") {
				margin: 0 80px;
				width: 1px;
			}
		}
	}

	.navbar {

		@include media("<tablet") {
			box-shadow: rem-calc(6) rem-calc(6) rem-calc(40) rgba($black, .3);
		}


		>.container {
			min-height: auto;

			@include media("<tablet") {
				display: flex;
				align-items: center;
				padding: rem-calc(13 20) !important;
			}

			// @include media(">=desktop") {
			// 	min-height: rem-calc(136);
			// }

			@include media(">=widescreen") {
				padding-top: rem-calc(11);
			}
		}
	}

	.navbar-menu {
		flex-shrink: 1;
	}

	.navbar-end {
		width: 100%;
	}

	.navbar-brand {
		min-height: 1px;
		flex-shrink: 1;

		.navbar-item {
			padding: 0;

			img {
				max-height: none;
				max-width: 100%;
			}
		}
	}

	.logo {
		max-width: rem-calc(122);
		flex-shrink: 1;
		display: block;

		@include media(">=desktop") {
			max-width: rem-calc(190);

			margin: {
				top: 0;
				bottom: 0;
			}
		}

		@include media(">=widescreen") {
			max-width: rem-calc(250);
			/* margin-top: rem-calc(20);
			margin-bottom: rem-calc(20); */
		}
	}

	.navbar-dropdown {

		.navbar-item,
		.navbar-link {

			@include media(">=tablet") {
				text-align: left;
			}
		}
	}

	.second-navbar {
		background-color: #fff;
		box-shadow: 0 3px 5px #00000016;
		pointer-events: auto;
		padding: 30px 1.25rem;
		display: flex;
		justify-content: center;
		position: relative;
		z-index: 999;

		@media(max-width:767px) {
			padding: 18px 24px;
		}

		a {
			transition: color ease 0.5s;
		}

		&-brand {
			align-self: center;

			.logo img {
				width: auto;
				height: 102px;
				margin-bottom: -10px;
				max-width: none;
				vertical-align: middle;
				transition: all ease 0.4s;

				@media(max-width:767px) {
					height: 50px;
				}

				@media(min-width: 768px) {
					.sticky-active & {
						height: 57px;
						margin-top: -9px;
					}
				}

			}

		}

		&-wrapper {
			display: grid;
			grid-template-columns: auto 1fr;
			gap: 20px;

			@media(min-width: 768px) and (max-width: 1659px) {
				position: relative;
			}
		}

		&-menu-holder {
			display: flex;
			align-items: flex-end;
			flex-direction: column;
			justify-content: flex-end;
			@media(max-width:1659px) {
				align-self: center;
			}

			ul {
				::before {
					display: none;
				}

				li {
					padding: 0;
					margin: 0;
					position: static;
				}
			}

		}

		&-top,
		&-bottom {
			ul {
				display: flex;
				flex-wrap: wrap;
				gap: 14px 28px;
				justify-content: flex-end;
			}
		}


		&-top {
			ul {
				flex-wrap: nowrap;
				gap: 0;

				li {
					margin-left: 28px;
					font-size: 15px;
					flex-shrink: 0;

					sup {
						display: inline-block;
						margin-top: -1em;
					}

					a {
						color: $blue-darkest
					}

					.icon svg {
						width: 24px;
						height: 24px;
					}

					.btn-burger {
						border: none;
						padding: 0;
						background-color: transparent;
						cursor: pointer;

						&:hover {
							color: $link;
						}
					}

					&.btn-burger-wrapper {
						display: none;

						@media (max-width: 1659px) {
							display: inline-block;
						}
					}
				}
			}
			.icon {
				transition: all ease 0.4s;
				width: 20px;
				height: 20px;
			}
			.text-login {
				transition: all ease 0.4s;
				vertical-align: top;
				display: none;
			}
			@media(min-width: 1660px) {
				margin-top: -29px;
				transition: all ease 0.4s;
				position: relative;
				top: -30px;
				.user {
					opacity: 1;
					.login & {
						opacity: 0;
						pointer-events: none;
						margin-left: -20px;
					}

				}
				.text-login{
					opacity: 1;
					display: inline;
				}
				.sticky-active & {
					top: 30px;
					.user {
						opacity: 1;
					}
					.text-login{
						opacity: 0;
						pointer-events: none;
					}
				}
			}
		}

		&-bottom {
			font-size: 16px;
			display: flex;
			justify-content: flex-end;
			gap: 30px;
			transition: all ease 0.4s;
			@media(min-width: 1660px){
				margin-right: 0;
				.sticky-active & {
					margin-right: 104px;
				}
				.sticky-active.login &{
					margin-right: 156px;
				}

			}

			@media(min-width: 768px) and (max-width: 1659px) {
				max-height: calc(100vh - 152px);
				overflow: auto;
			}

			ul {
				margin: 0;

				li {

					a,
					.dropdown-icon {
						color: $blue-darkest;
					}

					.main-menu-item-wrapper {
						text-transform: uppercase;
						display: flex;
						gap: 15px;
						align-items: center;
						cursor: pointer;
						position: relative;
						font-weight: 700;

						&::before {
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 5px;
							z-index: 1;
							background-color: $pink;
							display: block;
							margin-bottom: -30px;
							pointer-events: none;
							opacity: 0;
							transition: opacity ease 0.4s;
						}

						&::after {
							content: '';
							position: absolute;
							top: 100%;
							left: 0;
							width: 100%;
							height: 10rem;
							z-index: 1;
							cursor: auto;
							pointer-events: none;
						}
					}

					.dropdown-icon {
						display: block;
						width: 1em;
						transition: all ease 0.5s;

						svg {
							display: block;
						}
					}

					.main-menu-dropdown-megamenu {
						position: absolute;
						top: 100%;
						width: 100%;
						left: 0;
						padding: 5rem 1.25rem;
						display: flex;
						justify-content: center;
						background: $blue-darkest;
						pointer-events: none;
						opacity: 0;
						transition: all ease 0.5s;

						@media(min-width: 1660px) {
							max-height: calc(100vh - 120px);
							overflow: auto;
						}

						&-wrapper {
							position: relative;
							z-index: 99;
						}

						a {
							color: white;

							* {
								color: inherit;
							}
						}

						&-top-heading {
							h2 {
								color: white;
								font-size: 36px;
								font-weight: 800;

								&::after {
									content: "";
									font-size: 1em;
									line-height: 1;
									font-family: "icomoon";
									font-weight: 400;
									color: $pink;
									display: inline-block;
									margin-left: 0.5em;
								}
							}
						}

						&-top-grid {
							display: grid;
							grid-template-columns: repeat(6, 1fr);
							gap: 40px;
							margin-top: 40px;

							h3 {
								font-size: 18px;
								margin: 20px 0 0;
								line-height: 1.4;
								font-weight: 600;
							}
						}

						.article-top-content {
							figure {
								position: relative;
								aspect-ratio: 240/150;
								overflow: hidden;

								img {
									position: absolute;
									width: 100%;
									height: 100%;
									object-fit: cover;
									transition: 0.4s transform ease;
								}

								&:hover {
									img {
										transform: scale(1.15);
									}
								}
							}
						}

						&-bottom-content {
							margin-top: 50px;
						}

						&-bottom-heading {
							h2 {
								color: white;
								font-size: 26px;
								font-weight: 800;
							}
						}

						&-bottom-grid {
							display: grid;
							grid-template-columns: repeat(10, 1fr);
							gap: 40px;
							margin-top: 30px;

							h3 {
								font-size: 13px;
								margin: 15px 0 0;
								text-align: center;
								line-height: 1.4;
								font-weight: 600;
							}
						}

						.article-bottom-content {
							p {
								padding: 15px 20px;
								margin: 0;
								line-height: 1;
								text-align: center;
								font-size: 28px;
								border-radius: 2px;
								font-weight: 600;
								background-color: $cyan;
								transition: background ease 0.4s;
							}

							figure {
								position: relative;
								aspect-ratio: 130/60;

								img {
									position: absolute;
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}
						}

						a:hover {
							.article-bottom-content {
								p {
									background-color: $cyan-dark;
								}
							}
						}

						&-aside-content {
							grid-column-start: 5;
							grid-column-end: 7;
							grid-row-start: 1;
							grid-row-end: 3;

							ul {
								gap: 0;
								flex-direction: column;

								li {
									font-size: 22px;
									border-top: 1px solid #ffffff4d;
									padding: 20px 0;

									a {
										&::after {
											content: "";
											font-size: 1em;
											line-height: 1;
											font-family: "icomoon";
											font-weight: 400;
											color: $pink;
											display: inline-block;
											margin-left: 0.5em;
										}
									}
								}
							}
						}

						&-aside-heading h2 {
							color: white;
							font-size: 26px;
							margin-bottom: 30px;
						}
					}

					.is-featured {
						grid-column-start: 1;
						grid-column-end: 3;
						grid-row-start: 1;
						grid-row-end: 3;
						display: block;
						font-size: 20px;

						figure {
							aspect-ratio: 520/360;
						}

						h3 {
							font-size: 26px;
							margin-bottom: 15px;
						}
					}

					@media(min-width: 1660px) {
						.main-menu-item-wrapper {
							&.is-active {
								* {
									color: $pink;
								}

								&::before {
									opacity: 1;
								}
							}
						}

						&.main-menu-item {
							.main-menu-dropdown-megamenu {
								overflow: auto;
							}

							&:hover {
								.main-menu-item-wrapper {
									&>a {
										color: $pink;
									}

									&::before {
										opacity: 1;
									}

									&::after {
										pointer-events: auto;
									}

								}

								.main-menu-dropdown-megamenu {
									opacity: 1;
									pointer-events: auto;
								}

								.dropdown-icon {
									transform: rotate(180deg);
									color: $pink;
								}
							}
						}
					}
				}
			}

			ul.aux-nav {
				border-left: 1px solid $blue-darkest;
				padding-left: 30px;

				li {
					.main-menu-item-wrapper {
						text-transform: none;

					}

					.main-menu-dropdown-megamenu {
						background-color: $light-blue;

						&-top-heading h2 {
							color: $blue-darkest;
						}

						a {
							color: $blue-darkest;
						}
					}

				}
			}

			@media (max-width: 1659px) {
				position: absolute;
				top: 100%;
				right: 0;
				flex-direction: column;
				background: $midnight-blue;
				gap: 0;
				justify-content: flex-start;

				transition: all 0.4s ease;
				width: 480px;

				.container {
					margin: 0;
				}

				@media(min-width: 768px) {
					opacity: 0;
					transform: translateY(-15px);
					pointer-events: none;

					&.open-menu {
						opacity: 1;
						transform: translateY(0);
						pointer-events: auto;
					}
				}

				@media(max-width: 767px) {
					width: 100%;
					height: 0;
					overflow-y: auto;

					&.open-menu {
						height: calc(100vh - 86px);
					}
				}

				ul {
					flex-direction: column;
					flex-wrap: nowrap;
					gap: 0;

					&.aux-nav {
						padding: 0;
						border: none;
					}

					a,
					.dropdown-icon {
						color: white !important;
					}

					&>li {
						border-bottom: 1px solid rgba(255, 255, 255, 0.25);
					}

					.main-menu-item-wrapper {
						padding: 16px 24px;

						&::after,
						&::before {
							display: none !important;
						}

						&>a {
							flex-grow: 1;

							&:hover {
								color: #d62c77 !important;
							}

							&::after {
								content: "";
								font-size: 1em;
								line-height: 1;
								font-family: "icomoon";
								font-weight: 400;
								color: #d62c77;
								display: inline-block;
								margin-left: 0.5em;
							}
						}
					}

					li {

						.main-menu-dropdown-megamenu {
							grid-template-rows: 0fr;
							display: grid;
							position: static;
							opacity: 1;
							padding: 0;
							justify-content: initial;
							pointer-events: auto;
							background-color: transparent !important;

							&>.container {
								overflow: hidden;
								width: 100%;

								.main-menu-dropdown-megamenu-wrapper {
									padding: 16px 24px;
								}

								.main-menu-dropdown-megamenu-top-heading {
									display: none;
								}

								.main-menu-dropdown-megamenu-top-grid {
									margin: 0;
									display: flex;
									flex-direction: column;
									gap: 16px;

									a:not(.is-featured) {
										opacity: 0.7;

										&:hover {
											opacity: 1;
										}

										.article-top-content {
											figure {
												display: none;
											}
										}
									}

									a:is(.is-featured) {
										.article-top-content {
											display: grid;
											grid-template-columns: 120px 1fr;
											gap: 0 20px;
										}

										figure {
											grid-row-start: 1;
											grid-row-end: 4;
										}

										p,
										.link-more {
											font-size: 12px;
										}

										p {
											margin: 10px 0;
										}
									}
								}

								.article-top-content {
									h3 {
										font-size: 16px;
										font-weight: 400;
										margin: 0;
									}
								}
							}

							.main-menu-dropdown-megamenu-bottom-content,
							.main-menu-dropdown-megamenu-aside-content {
								margin-top: 32px;
							}

							.main-menu-dropdown-megamenu-aside-content {
								li {
									font-size: 16px;

									a {
										opacity: 0.85 !important;
									}
								}
							}

							.main-menu-dropdown-megamenu-bottom-heading,
							.main-menu-dropdown-megamenu-aside-heading {
								h2 {
									font-size: 16px;
									font-weight: 500;
								}
							}

							.main-menu-dropdown-megamenu-bottom-grid {
								grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
								gap: 8px;

								.article-bottom-content {
									p {
										padding: 10px 8px;
										font-size: 14px;
									}

									h3 {
										font-size: 8px;
									}
								}
							}
						}
					}
				}

				.open {
					background-color: $blue-darkest !important;
					transition: background-color 0.4s ease;

					.main-menu-dropdown-megamenu {
						grid-template-rows: 1fr;
					}

					.dropdown-icon {
						transform: rotate(180deg);
						color: $link !important;
					}
				}
			}
		}
	}

}

.navbar-menu-holder {
	flex-grow: 1;
	padding-left: rem-calc(20);
	margin-right: rem-calc(-12);

	@include media(">=tablet") {}
}

.navbar-top {

	@include media(">=widescreen") {
		//padding-bottom: rem-calc(9);
	}
}

.add-menu-burger {

	@include media("<tablet") {
		position: fixed;
		bottom: rem-calc(22);
		right: rem-calc(3);
		font-size: rem-calc(28);
		line-height: 1;
		color: $info;
		padding: rem-calc(10 10);
		z-index: 10;
	}

	@include media(">=tablet") {
		display: none;
	}

	&:hover,
	&:active,
	&:focus,
	&.is-active {
		color: $dark;
	}

	.icon {
		@include size(auto);
	}
}

.add-menu {
	font-size: rem-calc(14);
	letter-spacing: -.0357em; // -.5px
	font-weight: $weight-semibold;

	@include media("<tablet") {
		@include animate(opacity visibility, .15s, ease-out);
		position: fixed;
		left: 0;
		right: 0;
		bottom: rem-calc(88);
		top: auto;
		text-align: right;
		padding-top: rem-calc(16);
		padding-bottom: rem-calc(16);
		display: block !important;
		box-shadow: 0 rem-calc(-6) rem-calc(20) rgba($black, .15) !important;
		border-bottom: 1px solid $grey-lighter;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		z-index: 10;
	}

	@include media(">=tablet") {
		font-size: rem-calc(13);
		margin-right: rem-calc(-3) !important;
	}

	@include media(">=desktop") {
		font-size: rem-calc(15);
		letter-spacing: -.0333em; // -.5px
	}

	&.is-active {

		@include media("<tablet") {
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
		}
	}

	.navbar-end {

		@include media("<tablet") {
			display: flex;
			flex-direction: column-reverse !important;
		}
	}

	.navbar-item {
		line-height: 1.6429; // 23px

		@include media("<tablet") {
			padding-left: rem-calc(19);
			padding-right: rem-calc(19);
		}

		@include media(">=tablet") {
			padding-left: rem-calc(10);
			padding-right: rem-calc(10);
		}

		@include media(">=desktop") {
			line-height: 1.7333; // 26px
		}

		&.is-active {
			color: $link;
		}
	}

	a.navbar-item {

		&:hover {
			background: transparent;
		}
	}
}

.search-opener {
	font-size: rem-calc(28);
	line-height: 1;

	@include media(">=tablet") {
		font-size: rem-calc(22);
	}

	&.is-active,
	.is-active>& {
		color: $dark;
		background: transparent;

		.icon {

			&-close {
				opacity: 1;
			}

			&-search,
			&-search-thick {
				opacity: 0;
			}
		}
	}

	&:hover,
	&:focus {
		color: $link;
	}

	.icon {
		@include animate(opacity, .15s, ease-out);
		@include size(auto);

		&-close {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			opacity: 0;

			@include media(">=tablet") {
				font-size: rem-calc(18);
			}
		}

		&-search-thick {

			&:before {

				@include media(">=tablet") {
					content: $icon-search;
				}
			}
		}
	}

}

.search-dropdown-wrap {

	@include media("<tablet") {
		position: static;
	}

	@include media(">=tablet") {
		z-index: 31;
	}

	&.is-active {
		.navbar-dropdown {
			@keyframes navAnim {
				0% {
					transform: translateY(-40%);
					opacity: .25;
				}

				25% {
					transform: translateY(-30%);
					opacity: .5;
				}

				50% {
					transform: translateY(-20%);
					opacity: .75;
				}

				75% {
					transform: translateY(-10%);
					opacity: 1;
				}

				100% {
					transform: translateY(0%);
					opacity: 1;
					z-index: 100;
				}
			}

			animation: navAnim .4s;

			@include media("<tablet") {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&.is-hiding {
		.navbar-dropdown {
			@keyframes navSlideUp {
				0% {
					display: block;
					opacity: .8;
					transform: translateY(-5%);
				}

				25% {
					transform: translateY(-10%);
				}

				50% {
					transform: translateY(-15%);
				}

				100% {
					transform: translateY(-20%);
					display: none;
					opacity: .3;
				}
			}

			animation: navSlideUp;
			animation-duration: .7s;
		}
	}


	.navbar-dropdown {
		@include animate(opacity visibility, .15s, ease-out);
		padding-top: rem-calc(33);
		padding-bottom: rem-calc(33);
		border: 0;
		box-shadow: 0 rem-calc(6) rem-calc(20) rgba($black, .2) !important;

		@include media("<tablet") {
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
			box-shadow: 0 rem-calc(6) rem-calc(20) rgba($black, .2);
			opacity: 0;
			visibility: hidden;
		}

		@include media(">=tablet") {
			margin-top: rem-calc(5);
		}

		>.navbar-item,
		>a.navbar-item,
		>a.navbar-link {

			@include media(">=tablet") {
				padding-left: rem-calc(24);
				padding-right: rem-calc(24);
			}
		}

		>a.navbar-item,
		>a.navbar-link {
			font-size: rem-calc(18);
			line-height: 1.5; // 27px
			letter-spacing: -0.0278em; // -.5px
			font-weight: $weight-semibold;

			@include media("<tablet") {
				padding-left: rem-calc(42);
			}

			@include media(">=tablet") {
				font-size: rem-calc(14);
				line-height: 1.9286; // 27px
			}

			@include media(">=desktop") {
				font-size: rem-calc(18);
				line-height: 1.5; // 27px
			}

			&:hover {
				background: transparent;
			}
		}
	}

	h6 {
		font-size: rem-calc(19);
		line-height: 1.3684; // 26px
		font-weight: $weight-bold;
		margin-bottom: 0;

		@include media(">=tablet") {
			font-size: rem-calc(17);
			line-height: 1.5294; // 26px
		}

		@include media(">=desktop") {
			font-size: rem-calc(19);
			line-height: 1.3684; // 26px
		}
	}

	.search-form {
		width: 100%;
		margin-bottom: rem-calc(20);

		label,
		.label {
			font-size: rem-calc(19);
			line-height: 1.3684; // 26px
			font-weight: $weight-bold;
			margin-bottom: rem-calc(20);

			@include media(">=tablet") {
				font-size: rem-calc(17);
				line-height: 1.5294; // 26px
				margin-bottom: rem-calc(10);
			}

			@include media(">=desktop") {
				font-size: rem-calc(19);
				line-height: 1.3684; // 26px
				margin-bottom: rem-calc(20);
			}
		}

		.input:not(.is-small):not(.is-medium):not(.is-large) {
			border-radius: 0;
			font-size: rem-calc(15); // 15px/26px
			padding-top: .7333em; // 11px
			padding-bottom: .7333em; // 11px
			height: 3.3333em; // 50px

			&::placeholder {
				font-style: italic;
			}
		}

		.btn-search {
			color: $info;
			font-size: rem-calc(20);
			line-height: 1;
			right: rem-calc(11);

			.icon {
				display: inline-block;
				@include size(auto);
			}

			&:hover {
				color: $dark;
			}
		}
	}
}

.navbar-bottom {
	display: flex;
	font-size: rem-calc(12);
	font-weight: $weight-semibold;
	letter-spacing: -.0417em; // -.5px

	@include media(">=phone") {
		font-size: rem-calc(14);
		letter-spacing: -.0357em; // -.5px
	}

	@include media("<tablet") {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		background: $white;
		box-shadow: 0 rem-calc(-6) rem-calc(20) rgba($black, .2);
		justify-content: space-around;
		padding-left: rem-calc(12);
		padding-right: rem-calc(30);
	}

	@include media(">=xl-phone", "<tablet") {
		padding-right: rem-calc(44);
	}

	@include media(">=tablet") {
		justify-content: flex-end;
		font-size: rem-calc(13);
		font-weight: $weight-bold;
	}

	@include media(">=xl-tablet") {
		font-size: rem-calc(16);
		letter-spacing: -.0318em;
	}

	@include media(">=desktop") {
		font-size: rem-calc(18);
		position: relative;
		float: right;
		clear: both;
	}

	@include media(">=widescreen") {
		font-size: rem-calc(20);
	}

	@include media(">=fullhd") {
		font-size: rem-calc(22);
	}

	>.navbar-item {
		padding-left: rem-calc(5);
		padding-right: rem-calc(5);
		line-height: 1.5; // 18px

		@include media(">=phone") {
			line-height: 1.2857; // 18px
			padding-left: rem-calc(8);
			padding-right: rem-calc(8);
		}

		@include media("<tablet") {
			flex-shrink: 1;
			flex-grow: 1;
			flex-basis: auto;
			position: static;
		}

		@include media(">=tablet") {
			padding-left: 0;
			padding-right: 0;
		}

		@include media(">=desktop") {
			position: static;
		}


		&:last-child {

			>.navbar-dropdown {

				@include media(">=tablet", "<desktop") {
					left: auto;
					right: 0;
				}
			}
		}

		&.is-hoverable:hover {

			>.navbar-dropdown {

				@include media(">=desktop") {
					z-index: 30 !important;
				}
			}
		}

		&.is-visible-active-dropdown {

			>.navbar-dropdown {

				@include media(">=desktop") {
					opacity: 1;
					visibility: visible;
					pointer-events: auto;
				}
			}
		}

		>.navbar-link {
			line-height: 1.5; // 18px
			border-top: rem-calc(5) solid $primary;

			@include media(">=phone") {
				line-height: 1.2857; // 18px
			}

			@include media("<tablet") {
				padding: rem-calc(5 0 17);
				min-height: rem-calc(88);
				display: flex;
				align-items: center;
				background: transparent;
			}

			@include media(">=tablet") {
				border-top: 0;
				padding-bottom: rem-calc(14);
			}

			@include media(">=fullhd") {
				padding: rem-calc(11 16 17);
			}

			@include media(">=1344px") {
				padding-left: rem-calc(23);
				padding-right: rem-calc(23);
			}

			&:after {

				@include media(">=tablet") {
					@include animate(opacity, .15s, ease-out);
					content: "";
					position: absolute;
					left: rem-calc(12);
					right: rem-calc(12);
					bottom: rem-calc(6);
					height: rem-calc(4);
					background: $link;
					box-shadow: 0 rem-calc(4) rem-calc(4) rgba($black, .25);
					opacity: 0;
				}

				@include media(">=fullhd") {
					left: rem-calc(16);
					right: rem-calc(16);
					height: rem-calc(5);
				}

				@include media(">=1344px") {
					left: rem-calc(23);
					right: rem-calc(23);
				}
			}

			&.is-active {
				border-top-color: $dark;
				color: $dark;

				&:after {

					@include media(">=tablet") {
						opacity: 1;
					}
				}

				+.navbar-dropdown {

					@include media(">=desktop") {
						opacity: 1;
						visibility: visible;
						pointer-events: auto;
					}
				}
			}
		}

		>.navbar-dropdown {

			@include media("<tablet") {
				@include animate(opacity visibility, .15s, ease-out);
				position: absolute;
				bottom: 100%;
				left: 0;
				right: 0;
				max-height: calc(100vh - 88px);
				overflow: auto;
				background: $white;
				padding-bottom: rem-calc(17);
				padding-top: rem-calc(12);
				border-bottom: 1px solid $grey-lighter;
				border-top: rem-calc(5) solid $dark;
				box-shadow: 0 rem-calc(-6) rem-calc(20) rgba($black, .15);
			}

			@include media(">=tablet") {
				top: 100% !important;
			}

			@include media(">=desktop") {
				box-shadow: none !important;
				border-radius: 0 !important;
				display: flex !important;
				transform: translate(0) !important;
				width: 100%;
			}

			>.navbar-item {

				+.navbar-item {

					&:before {

						@include media(">=desktop") {
							@include size(1px, rem-calc(31));
							content: "";
							position: absolute;
							left: 0;
							top: 50%;
							transform: translateY(-50%);
							background: rgba($black, .2);
							height: 100%;
						}
					}
				}
			}

			.navbar-item {

				@include media("<tablet") {
					padding-right: rem-calc(7);
				}

				&.is-active {

					>.navbar-link {

						&:after {

							@include media("<tablet") {
								transform: rotate(-225deg);
								margin-top: rem-calc(-5);
								border-color: currentColor;
							}
						}
					}

					>.navbar-dropdown {

						@include media("<tablet") {
							display: block;
						}
					}
				}

				>.navbar-link {

					&.is-active {

						&:after {

							@include media("<tablet") {
								border-color: currentColor;
							}
						}
					}

					&:not(.is-arrowless) {

						@include media("<tablet") {
							padding-left: 0;
						}
					}
				}

				.navbar-item {

					@include media("<tablet") {
						padding-right: 0;
					}
				}
			}
		}
	}

	.navbar-item {

		&.is-active {

			>.navbar-dropdown {

				@include media("<tablet") {
					opacity: 1;
					visibility: visible;
					pointer-events: auto;
				}
			}
		}

		.navbar-link:not(.is-arrowless) {

			&:after {
				@include size(rem-calc(10));
			}
		}
	}

	.navbar-dropdown {

		@include media("<tablet") {
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
		}

		@include media(">=tablet") {
			font-size: rem-calc(13);
		}

		@include media(">=widescreen") {
			font-size: rem-calc(15);
		}



		.navbar-dropdown {

			@include media("<tablet") {
				display: none;
				padding-top: 0;
				padding-bottom: 0;
			}
		}

		.navbar-item {

			@include media(">=desktop") {
				flex-shrink: 1;
				white-space: normal;
				align-items: flex-start;
				max-width: 30%;
				align-items: center;
			}

			@include media(">=1340px") {
				padding-left: rem-calc(22);
			}

			>.navbar-link {

				@include media(">=tablet") {
					padding: 0;
				}

				&.is-active {

					@include media(">=tablet") {
						color: $link;
					}
				}

				&:after {

					@include media(">=tablet") {
						display: none;
					}
				}
			}
		}

		a.navbar-item {

			@include media(">=desktop") {
				padding-right: rem-calc(16);
				min-height: rem-calc(38);
			}

			@include media(">=widescreen") {
				min-height: rem-calc(48);
				line-height: 1.2; // 18px
			}

			&.is-active,
			&:hover,
			&:focus,
			&:active {
				background: transparent;
			}
		}

		.navbar-link {

			&.is-active,
			&:hover,
			&:focus,
			&:active {
				background: transparent;
			}
		}

		.navbar-divider {
			margin-left: rem-calc(19);
			margin-right: rem-calc(19);
		}

		h6 {
			font-size: rem-calc(17);
			line-height: 1.3529; // 23px

			.icon {
				@include size(auto);
				display: inline-block;
				font-size: rem-calc(14);
				line-height: 1;
				margin-top: -3px;
				margin-left: rem-calc(5);
			}
		}
	}
}

.options-links-header-search {
	padding-left: .75rem;
}

.navbar-top {
	&.is-hidden-touch {
		.add-menu {
			&.navbar-menu {
				.navbar-item {
					&:focus {
						background-color: inherit;
					}
				}
			}
		}

		.search-dropdown-wrap {
			a.navbar-link {
				&.is-arrowless {
					&.search-opener {

						&:hover,
						&:active,
						&:focus {
							background-color: inherit !important;
						}
					}
				}
			}
		}
	}
}

.navbar-menu-holder.is-flex-desktop {
	justify-content: flex-end;
	align-items: center;
}

@include media('>tablet') {
	.navbar-top.is-flex.is-hidden-touch {

		.navbar-item.has-dropdown.is-active .navbar-link,
		.navbar-item.has-dropdown:focus .navbar-link,
		.navbar-item.has-dropdown:hover .navbar-link {
			background-color: inherit;
		}
	}
}