/*

Propuesta de colores y su remplazo

#dc2126 - 1::$intense-red
#F26222 - 1::$bright-orange
#BC61A6 - 1::$light-pink
#8F2371 - 1::$dark-purple

#025793 - 1::$navy-blue
#0b5793 - 2::$blue

#24366A - 1::$deep-blue
#273667 - 2::$midnight-blue

#4FA3AD - 1::$teal
#4fa3ae - 2::$teal

#82A03D - 1::$olive-green
#95a329 - 2::$custom-green

#B4DBF4 - 1::$light-blue
#bbdaf2 - 2::$sky-light-alt

#C2E6EA - 1::$aqua-light
#cce5e4 - 2::$aqua-light

#DBE120 - 1::$lime-green
#dde04f - 2::$lime

#E9F6F9 - 1::$sky-blue
#e7f5f3 - 2::$sky-blue

#FBA919 - 1::$yellow-orange
#de9502 - 2::$custom-yellow

#E80680 - 1::$fuchsia
#d62c77 - 2::$pink


*/
// custom variables and override bulma variables

// Colors
$black: #231F20;
$grey-darker: #231f20; // -> heading in aside search filter
$grey-dark: #565656; // hsl(0, 0%, 35%) -> body text color, footer copyright
$grey: #565656; // hsl(0, 0%, 46%) -> date color, , text in card, figcaption, news-text
$grey-light-alt: #929292; // hsl(0, 0%, 57%) -> select text color, datepicker placeholder, search field placeholder
$grey-light: #787678; // hsl(0, 0%, 71%) -> placeholder, blockquote border
$grey-lighter: #e0e0e0; // hsl(0, 0%, 88%) -> accordion borders
$off-white: #e8f0ef;
$grey-for-table: #b7b7b7;
$secondary-grey-for-table: #e0e0e0;

$intense-red: #dc2126;
$bright-orange: #F26222;
$light-pink: #BC61A6;
$dark-purple: #8F2371;
$light-blue: #B4DBF4;

$lime-green: #DBE120;

$custom-red: #DC2227;
$custom-green: #dde04f;
$custom-green-alt: #edf134;
$custom-yellow: #FBA919;
$lime: #DBE120;
$lime-dark: #cac028;

$pink: #E80680; // inline link color, more link color
$pink-dark: #b51a5e; // bg of close btn

$blue-dark: #3e4462; // note text under page heading
$blue: #025793; // main nav links, links list
$midnight-blue: #24366A; // main nav links, links list
$blue-darkest: #17244D; // headings, list bullets, active main nav
$blue-dark-alt: #111c41; // main nav links, links list
$sky-blue: #E9F6F9;
$teal: #4FA3AD; // gallery arrows, card borders
$cyan: #429aa6; // links in headings, category, learn-cards bg, tags border
$cyan-dark: #46949e; // btn download
$medium-teal: #a7d1d7;
$aqua-light: #C2E6EA;
$sky-blue-alt: #f4faf9; // bg-light
$white: #ffffff;
$yellow-warning: #ffdd57;
$green-success: #48c774;
$pink-danger: #f14668;

$primary: $cyan;
$primary-dark: $cyan-dark;
$primary-lighter: $teal;
$primary-lightest: $sky-blue;
$primary-light: $aqua-light;

$info: $blue;
$info-dark: $blue-dark;

$light: $sky-blue-alt;
$dark: $blue-darkest;

$text: $grey-dark;

// Link colors
$link: $pink;
$link-dark: $pink-dark;
$link-hover: $link-dark;
$link-focus: $link-hover;
$link-active: $link-hover;

// Typography
$family-sans-serif:
  "Lexend",
  "Montserrat",
  -apple-system,
  "Segoe UI",
  "Roboto",
  "Oxygen",
  "Ubuntu",
  "Cantarell",
  "Fira Sans",
  "Droid Sans",
  "Helvetica Neue",
  "Helvetica",
  "Arial",
  sans-serif;

$rem-base: 16px;

$size-4: 1.875rem; // 30px
$size-6: 1.0625rem; // 17px

$size-medium: 1rem; // 16px
$size-smaller: 0.9375rem; // 15px
$size-large: $size-4; // 30px

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 40px;
$tablet: 768px;
$fullhd: 1200px + (2 * $gap);

// Miscellaneous
$radius-xsmall: 2px;
$radius-small: 5px;
$radius: 8px;
$radius-large: 15px;
$radius-xlarge: 25px;

$body-min-width: 320px;

$column-gap: 1.25rem; // 20px

$body-font-size: 1.0625em; // 17px
$body-weight: $weight-medium; // 500
$body-line-height: 1.53; // 26px

$hr-background-color: $grey-lighter;
$hr-height: 1px;

$strong-color: inherit;
$strong-weight: $weight-semibold;

$section-padding: 3rem 1.25rem;
$section-padding-medium: 9rem 1.25rem;
$section-padding-large: 18rem 1.25rem;

$content-heading-color: $dark;
$content-heading-weight: $weight-bold;

$content-blockquote-background-color: transparent;
$content-blockquote-border-left: none;
$content-blockquote-padding: 0 3.75rem;
$content-blockquote-desktop-padding: 0 5.5rem;

$control-border-width: 1px;

$control-line-height: 1.7333; // 15/26

$control-padding-horizontal: calc(1.3333em - #{$control-border-width}); // 15px

// Buttons
$button-border-width: $control-border-width;
$button-padding-vertical: calc(0.824em - #{$button-border-width}); // 13px
$button-padding-horizontal: 1.2941em; // 22px

// Tags
$tag-radius: $radius-small;

// Forms
$input-background-color: $primary-lightest;
$input-border-color: $input-background-color;
$input-shadow: none;
$input-placeholder-color: $grey-light;
$input-focus-border-color: $dark;
$input-focus-box-shadow-color: rgba($input-focus-border-color, 0.25);
$input-arrow: $dark;
$input-radius: $radius-small;

$label-color: $dark;
$label-weight: $weight-medium;

// Cards
$card-color: $grey;
$card-background-color: transparent;
$card-radius: $radius-large;
$card-content-padding: 2rem 1.625rem 1.625rem; // 32/26/26
$card-header-padding: 0.75rem 1.625rem; // 12/26
$card-footer-padding: 0.75rem 1.625rem; // 12/26

// Navbar
// $navbar-background-color: $white;
$navbar-breakpoint: $tablet;
$navbar-height: 7.3125rem; // 117/216px
$navbar-padding-vertical: 1rem;
$navbar-padding-horizontal: 1.25rem;
$navbar-item-color: $info;
$navbar-item-active-color: $dark;
$navbar-dropdown-border-top: none;
$navbar-dropdown-radius: 0;
$navbar-dropdown-item-hover-color: $link;
$navbar-dropdown-arrow: $info;
$navbar-divider-background-color: #eee;
$navbar-divider-height: 1px;

// Progress
$progress-bar-background-color: $primary-lightest;

// Notification
$notification-padding: 1.375rem 2.5rem 1.375rem 1.5rem;
$notification-padding-ltr: 1.375rem 2.5rem 1.375rem 1.5rem;
$notification-padding-rtl: 1.375rem 1.5rem 1.375rem 2.5rem;

// Footer
$footer-background-color: $primary-lightest;
$footer-padding: 3.125rem 1.25rem 7.5rem; // 50/20/120
