.search-form {
	width: 100%;

	&:not(:last-child) {
		margin-bottom: rem-calc(20);
	}

	.input:not(.is-small):not(.is-medium):not(.is-large) {
		padding-right: rem-calc(45);
	}

	.btn-search:not(.button) {
		@include animate(color, .15s, ease-out);
		background: transparent !important;
		border: 0;
		padding: rem-calc(5);
		margin: 0;
		border-radius: 0;
		color: $dark;
		font-size: rem-calc(18);
		line-height: 1;
		position: absolute;
		right: rem-calc(9);
		top: 50%;
		transform: translateY(-50%);

		.aside-column & {
			font-size: rem-calc(22);
		}

		.icon {
			@include size(auto);
			display: inline-block;
		}

		&:hover {
			color: $link;
		}
	}
}

.button-list-search {
	@include media("<tablet") {
		text-align: left;
	}

	.button {
		@include media(">desktop") {
			&:last-child {
				margin-left: rem-calc(15);
			}
		}

		@include media("<=desktop") {
			margin: {
				top: rem-calc(8);
				bottom: rem-calc(8);
			}

			;
		}
	}
}

form.search-sort-form {
	&>.columns {
		flex-wrap: nowrap;

		.column {
			min-width: 1px;
		}
	}

	&:not(.form-404) {
		.control .btn-search {
			transform: translateY(0);
		}
	}
}

.second-search {
	&-btn {
		position: relative;
		color: $blue-darkest;

		&-open {
			padding: 0;
			display: block;
			border: none;
			width: 20px;
			height: 20px;
			cursor: pointer;
			background-color: transparent;
			transition: all ease 0.4s;

			&:hover {
				color: $pink;
			}
		}

		.second-desktop & {
			&-close {
				cursor: pointer;
				position: absolute;
				z-index: 9999;
				width: 34px;
				height: 34px;
				padding: 10px;
				border: none;
				border-radius: 100%;
				background: $lime;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				opacity: 0;
				pointer-events: none;
				transition: opacity ease 0.4s;

				&.open {
					opacity: 1;
					pointer-events: auto;
				}
			}
		}

		.second-mobile & {
			&-open {
				&.open {
					opacity: 0;
					transform: translateX(-50%);
					pointer-events: none;
				}
			}
			&-close {
				position: absolute;
				left: 0;
				top: 0;
				display: block;
				border: none;
				width: 24px;
				height: 24px;
				padding: 2px;
				cursor: pointer;
				background-color: transparent;
				transition: color 0.4s ease;
				opacity: 0;
				transform: translateX(50%);
				pointer-events: none;
				transition: all ease 0.4s;
				
				&:hover {
					color: $pink;
				}
				
				&.open {
					opacity: 1;
					transform: translateX(0);
					pointer-events: auto;
				}
			}
		}
	}

	.second-desktop & {
		&-topbar {
			h2 {
				color: white !important;
				font-size: 30px;
				margin: 0;
			}
		}

		&-nav {

			&,
			* {
				margin-bottom: 0;
			}

			* {
				color: white !important;
			}

			h6 {}

			a {
				&:hover {
					color: $lime !important;
				}
			}
		}

		&-overlay {
			position: absolute;
			inset: 0;
			background: $blue-darkest;
			opacity: 0.95;
			z-index: -1;
		}

		&-wrapper {
			position: fixed;
			justify-content: center;
			inset: 0;
			z-index: 999;
			display: flex;
			opacity: 0;
			padding: 20px;
			pointer-events: none;
			transition: opacity ease 0.4s;

			&.open {
				opacity: 1;
				pointer-events: auto;
			}
		}

		&-content {
			margin-top: 260px;
			display: grid;
			grid-template-columns: auto 1fr;
			gap: 20px;
			align-items: flex-end;
		}

		&-form {
			display: grid;
			grid-template-columns: 350px 1fr;
			gap: 15px;
			grid-column: span 2 / span 2;

			select,
			input {
				background: white !important;
				height: 77px !important;
				font-size: 18px!important;

				&:placeholder {
					color: #000000 !important;
				}
			}

			.control {
				position: relative;
			}

			[type='submit'].button.btn-search {
				position: absolute;
				right: 12px;
				top: 12px;
				background: $lime;
				color: $blue-darkest !important;
				border: none;
				font-size: 22px!important;
				height: 53px!important;
				border:none!important;
				i {
					display: none;
				}
			}
		}
	}

	.second-mobile & {
		&-topbar {
			h2 {
				font-size: 16px;
				margin: 0;
			}
		}

		&-nav {
			flex-direction: column;
			justify-content: flex-start;
			order: 1;
			margin-bottom: 0;
			gap: 15px;

			h6 {
				margin-bottom: 0;
				font-weight: 400;
			}

			a {
				color: $cyan !important;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		&-overlay {
			display: none;
		}

		&-wrapper {
			position: absolute;
			right: 0;
			top: 100%;
			background: $sky-blue;
			width: 100%;
			display: grid;
			grid-template-rows: 0fr;
			transition:all 0.4s ease;
			> * {
				overflow: hidden;
				width: 100%;
			}
			
			&.open{
				grid-template-rows: 1fr;
			}
		}

		&-content {
			display: flex;
			flex-direction: column;
			gap: 15px;
			padding: 16px 24px;
		}

		&-form {
			display: grid;
			grid-template-columns: 1fr 2fr;
			gap: 15px;
			grid-column: span 2 / span 2;

			select,
			input {
				background: white !important;
				height: 44px !important;
				padding: 10px 15px;

				&:placeholder {
					color: #000000 !important;
				}
			}

			.control {
				position: relative;
			}

			[type='submit'].button.btn-search {
				position: absolute;
				right: 0;
				top: 0;
				background: transparent;
				color: $blue-darkest !important;
				border: none;
				height: 44px;
				padding: 10px 15px;
				display: flex;
				span {
					display: none;
				}
				i {
					width: 1em;
					margin: auto;
					 svg {
						display: block;
					 }
				}
			}
		}
	}
}