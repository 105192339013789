#footer {
	display: flex;
	justify-content: center;
	&.footer {
		background-color: $aqua-light;
	}
	.container {
		max-width: 1640px;

		@include media(">desktop") {
			margin: 0 80px;
			width: 1px;
		}
	}

	font-size: rem-calc(15); // 15px
	line-height: 1.5333; // 23px

	@include media(">=tablet") {
		padding: rem-calc(70 20 54);
	}

	h6 {
		font-size: 22px; // 15px
		line-height: 1.334; // 20px
		// min-height: 2.6667em;
		margin-bottom: 0.8em;
	}

	.box {
		padding: rem-calc(25 22);
		margin-bottom: rem-calc(21);
	}

	.btn-holder {
		margin-left: auto;

		.button {
			border-radius: 3px;
			height: auto !important;
			font-size: 20px !important;
			font-weight: 500;
			padding: 0.565rem 1.5rem !important;

		}
	}

	p {

		a {
			color: inherit;

			&:hover {
				color: $link;
			}
		}
	}

}

.add-nav-columns {

	>.column {

		@include media(">=tablet") {
			padding-bottom: rem-calc(8);
		}
	}
}

.add-nav {

	ul {
		font-size: 18px; // 14px
		line-height: 1.5; // 21
		letter-spacing: -.0143em; // -.2px

		>li {

			+li {
				margin-top: rem-calc(7);
			}
		}
	}
}

.add-sub-nav {

	@include media(">=tablet") {
		margin-bottom: 20px;
	}

	ul {
		width: 100%;
		font-weight: $weight-medium;
		font-size: 20px;
		line-height: 1.5333; // 23px
		letter-spacing: -0.0133em; // -.2px

		@include media(">=phone") {
			font-size: 20px;
			line-height: 1.4444; // 26px
		}

		@include media("<tablet") {
			// column-count: 2;
			// column-gap: 16px;
			margin-bottom: 1em;

			&>li {
				padding-bottom: 16px !important;
			}
		}

		@include media(">=tablet") {
			font-size: 20px;
			line-height: 1.5333; // 26px
			font-weight: $weight-normal;
		}

		>li {
			break-inside: avoid;
			padding-bottom: rem-calc(2);

			@include media(">=tablet") {
				padding-bottom: rem-calc(10);
			}

			&:not(:last-child) {

				@include media(">=tablet") {
					padding-right: rem-calc(22);
				}
			}
		}
	}
}

.copyright {
	letter-spacing: -0.0133em; // -.2px

	@include media(">=tablet") {
		margin-bottom: rem-calc(20);
	}

	p {

		&:last-child {
			margin-bottom: 0;
		}
	}
}
#footer .second-footer {
	.dropdown-icon {
		display: none;
	}
	.title-social-media {
		font-size: 20px;
	}
	@media(max-width:767px) {
		.btn-membership {
			width: 100%;
			text-align: center;
		}
		.social-media{
			display: flex!important;
			gap:20px;
			justify-content: center;
			.social-networks {
				
				justify-content: center;
			}
		}
		.add-sub-nav {
			ul {
				font-size:14px;
				display: flex;
				justify-content: space-around;
				gap:20px;
				li {
					padding: 0!important;
				}
			}
		}
		.add-nav {
			h6 {
				display: flex;
				gap:10px;
				font-size: 16px;
				.dropdown-icon {
					display: block;
					width: 0.8em;
					margin-left: auto;
					transition: all 0.4s ease;
					cursor: pointer;
				}
			}
			.add-nav-content {
				display:grid;
				grid-template-rows: 0fr;
				transition: all 0.4s ease;
				> :first-child{
					overflow: hidden;
				}
				ul {
					font-size: 14px;
				}
			}
	
			.add-nav-wrapper {
				& > *{
					margin-bottom: 0.8em;
				}
			}
			&.open {
				.dropdown-icon {
					transform: rotate(180deg);
				}
				.add-nav-content {
					grid-template-rows: 1fr;
				}
			}
		}
	}
}