.promo-person {
  .card-content {
    .content {
      .title-promo-person {
        font-size: rem-calc(24);
        font-weight: 600;

        &:not(:hover) {
          color: $content-heading-color;
        }
      }
    }
  }

  .body-promo-person {
    font-size: rem-calc(17);
    line-height: 1.5em;
    color: #757575;
  }

  .subtitle-promo-person {
    font-weight: $strong-weight;
    margin-bottom: 8px;
    display: block;
  }

  .description-promo-person {
    font-weight: 500;
  }
}
