.button {
	align-content: center;
	line-height: 1.2941; // 16px/22px
	font-weight: $weight-bold;
	transition: all ease 0.5s;
	border-radius: 3px !important;
	padding: 0.565rem 1.5rem;

	&:not(.tag):not(.is-small):not(.is-medium):not(.is-large),
	:not(.tags):not(.are-small):not(.are-medium):not(.are-large)>& {
		// height: 2.9412em; // 50px
	}

	&.is-small,
	.buttons.are-small & {

		&:not(.tag):not(.is-normal):not(.is-medium):not(.is-large) {
			// height: 2.125em;
			font-size: $size-medium; // 16px
			line-height: 1.375; // 22px
			font-weight: $weight-semibold;
			padding: .3125em 1.125em; // 5px/18px
		}
	}

	&.is-medium,
	.buttons.are-medium & {

		&:not(.tag):not(.is-small):not(.is-normal):not(.is-large) {
			// height: 3.3125em; // 53px
			line-height: 1.4375; // 16/23
			font-weight: $weight-normal;
			padding: .875em 1.375em; // 14px/22px
		}
	}

	&.is-large,
	.buttons.are-large & {

		&:not(.tag):not(.is-small):not(.is-normal):not(.is-medium) {
			// height: 2.1em; // 63px
			line-height: 1.1; // 30/33
			font-weight: $weight-semibold;
			padding: 0.4667em .8333em; // 14px/25px
		}
	}

	&.is-primary {

		&.is-light {
			background: $primary-lightest;

			&:hover {
				border-color: $primary;
				background-color: $primary;
				color: $white;
			}
		}

		&.is-dark {
			background: $primary-dark;

			&:hover {
				background-color: darken($primary-dark, 5%);
			}
		}
	}

	.icon {
		font-size: 1.4375em; // 23px
		font-weight: $weight-normal;

		&,
		&.is-small,
		&.is-medium,
		&.is-large {
			@include size(auto);
		}

		&.is-large {
			font-size: 1.4375em; // 23px
		}

		&.is-medium {
			font-size: 1em; // 17px
		}

		&.is-small {
			font-size: 0.8235em; // 14px
		}

		&:last-child:not(:first-child) {
			margin-left: 0.4783em; // 10px
			margin-right: 0;
		}

		&:first-child:not(:last-child) {
			margin-left: 0;
			margin-right: 0.4783em; // 10px
		}

		.icon {
			margin: 0 !important;
			font-size: 1em;
		}
	}
}

.action-hover-navigation {
	border-color: #429aa6 !important;
	background-color: #429aa6 !important;
	color: white !important;
}