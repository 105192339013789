body {
  min-height: 100vh;
  font-weight: 400;

  .has-link {
    --link-color: var(--text-color);
  }

  a:hover {
    color: $pink !important;
  }

  .has-text-primary-dark {
    color: $primary-dark !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $primary-dark;
    }
  }

  .has-background-grey-light,
  .has-background-medium-grey {
    background-color: $grey-light !important;
  }

  .has-text-grey-light,
  .has-text-medium-grey {
    color: $grey-light !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $grey-light;
    }
  }

  .has-text-blue {
    color: $blue !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $blue;
    }
  }

  .has-text-warning {
    color: $yellow-warning !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $yellow-warning;
    }
  }

  .has-text-success {
    color: $green-success !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $green-success;
    }
  }

  .has-text-danger {
    color: $pink-danger !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $pink-danger;
    }
  }

  .has-text-dark {
    color: $blue-darkest !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $blue-darkest;
    }
  }

  .has-text-blue-darkest {
    color: $blue-darkest !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $blue-darkest;
    }
  }

  @each $name, $pair in $progress-colors {
    $color: nth($pair, 1);

    .is-outlined-#{$name},
    .has-outlined-#{$name} {
      border: 1px solid $color;
    }
  }

  .is-outlined-green,
  .has-outlined-green {
    border: 1px solid $custom-green;
  }

  .is-outlined-off-white,
  .has-outlined-off-white {
    border: 1px solid $off-white;
  }

  .is-outlined-teal,
  .has-outlined-teal {
    border: 1px solid $primary-lighter;
  }

  .is-outlined-medium-teal,
  .has-outlined-medium-teal {
    border: 1px solid $medium-teal;
  }

  .is-outlined-grey-light,
  .has-outlined-medium-grey,
  .has-outlined-grey-light {
    border: 1px solid $grey-light;
  }

  .is-outlined-grey,
  .has-outlined-grey {
    border: 1px solid $grey;
  }

  .is-outlined-magenta,
  .has-outlined-magenta,
  .has-outlined-pink {
    border: 1px solid $pink;
  }

  .is-outlined-blue,
  .has-outlined-blue {
    border: 1px solid $blue-darkest;
  }

  .is-outlined-medium-blue,
  .has-outlined-medium-blue {
    border: 1px solid $blue;
  }

  .is-outlined-black,
  .has-outlined-black {
    border: 1px solid $black;
  }

  .is-outlined-red,
  .has-outlined-red {
    border: 1px solid $custom-red;
  }

  .is-outlined-yellow,
  .has-outlined-yellow {
    border: 1px solid $custom-yellow;
  }

  .is-outlined-red,
  .has-outlined-red {
    border: 1px solid $custom-red;
  }

  // ACTUALIZADO
  .is-outlined-primary-lightest,
  .has-outlined-primary-lightest,
  .has-outlined-pale-blue {
    border: 1px solid $primary-lightest;
  }

  .is-outlined-primary-lighter,
  .has-outlined-primary-lighter {
    border: 1px solid $primary-lighter;
  }

  .is-outlined-primary-light,
  .has-outlined-primary-light {
    border: 1px solid $primary-light;
  }

  .is-outlined-primary-dark,
  .has-outlined-primary-dark {
    border: 1px solid $primary-dark;
  }

  .is-size-smaller {
    font-size: $size-smaller; // 15px
    line-height: 1.5333; // 23px
  }

  #wrapper {
    position: relative;
    overflow: hidden;
  }

  .show-on-focus {
    @include size(1px);
    position: absolute;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;

    &:active,
    &:focus {
      position: static;
      width: auto;
      height: auto;
      overflow: visible;
      clip: auto;
      white-space: normal;
    }
  }

  .columns {
    @include media("<desktop") {
      margin-top: rem-calc(-10);
      margin-left: rem-calc(-10);
      margin-right: rem-calc(-10);
    }

    &:last-child {
      @include media("<desktop") {
        margin-bottom: rem-calc(-10);
      }
    }
  }

  .column {
    @include media("<desktop") {
      padding: rem-calc(10);
      max-width: 100%;
    }
  }

  .aside-column {
    clear: left;

    .aside-block {
      &:not(:last-child) {
        margin-bottom: rem-calc(25);

        @include media(">=desktop") {
          margin-bottom: rem-calc(35);
        }
      }

      h5 {
        letter-spacing: normal;
        margin-bottom: 0.47em;
      }

      ul:not(.side-nav),
      .content ul:not(.side-nav) {
        letter-spacing: -0.0294em; // -.5px
      }
    }

    .card {
      &:not(:last-child):not(.card-news.is-horizontal) {
        margin-bottom: rem-calc(22);

        @include media(">=desktop") {
          margin-bottom: rem-calc(60);
        }
      }

      &:not(:first-child):not(.card-news.is-horizontal) {
        @include media(">=desktop") {
          margin-top: rem-calc(60);
        }
      }
    }

    .box {
      padding: rem-calc(20 25);

      &:not(:last-child) {
        @include media(">=desktop") {
          margin-bottom: rem-calc(38);
        }
      }

      &:not(:first-child) {
        @include media(">=desktop") {
          margin-top: rem-calc(38);
        }
      }
    }

    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6,
    .section-heading {
      +.box {
        @include media(">=desktop") {
          margin-top: 0 !important;
        }
      }
    }
  }

  .content-section {
    clear: both;
  }

  .content-column {
    clear: right;

    >.content-section,
    >.card {
      &:last-child {
        @include media("<tablet") {
          margin-bottom: rem-calc(10) !important;
        }
      }
    }
  }

  .side-column {
    &.is-centered {
      margin: 0 auto;
    }

    &.is-pulled-right,
    &.is-pulled-left,
    &.is-centered {
      padding-bottom: rem-calc(20);

      @include media("<tablet") {
        width: 100%;
        font: none;
      }

      @include media(">=tablet") {
        width: rem-calc(348);
      }

      @include media(">=desktop") {
        padding-bottom: rem-calc(24);
      }

      &.is-sm {
        @include media(">=tablet") {
          width: rem-calc(300);
        }
      }

      &.is-md {
        @include media(">=tablet") {
          width: rem-calc(400);
        }
      }

      &.is-lg {
        @include media(">=tablet") {
          width: rem-calc(600);
        }
      }

      &.is-full-fluid {
        width: 100% !important;
      }
    }

    &.is-pulled-right {
      @include media(">=tablet") {
        padding-left: rem-calc(20);
      }

      @include media(">=desktop") {
        padding-left: rem-calc(40);
      }

      @include media(">=fullhd") {
        padding-left: rem-calc(40);
      }
    }

    &.is-pulled-left {
      @include media(">=tablet") {
        padding-right: rem-calc(20);
      }

      @include media(">=desktop") {
        padding-right: rem-calc(40);
      }

      @include media(">=fullhd") {
        padding-right: rem-calc(40);
      }
    }
  }

  .heading-row {
    padding-bottom: rem-calc(30);

    @include media(">=desktop") {
      padding-bottom: rem-calc(40);
    }
  }

  .page-heading {
    padding-bottom: rem-calc(20);
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include media(">=desktop") {
      padding-top: rem-calc(62);
      padding-bottom: rem-calc(40);
    }

    &-top {
      display: flex;
      gap: 16px;
      padding: 15px 0 32px;
      align-items: center;
      justify-content: space-between;

      .social-media {
        margin-bottom: -10px;
      }
    }

    h1,
    .h1,
    h2,
    .h2 {
      margin: 0;

      @include media(">=tablet") {
        margin-bottom: 0.15em; // 6px
      }

      &:not(:last-child) {
        margin-bottom: 0.15em; // 6px

        @include media(">=desktop") {
          margin-bottom: 0.3em; // 15px
        }
      }
    }

    &-title {
      margin: 0;
    }

    &-subtitle,
    .content {
      margin-top: 14px;
      font-size: 30px;
      font-weight: 600;
      line-height: 1.3; // 16px/24px
      letter-spacing: -0.4px;
    }

    h1 {
      @include media(">=tablet") {
        font-size: 58px;
      }

      line-height: 1.1;
      font-weight: 600;
      margin-bottom: 0 !important;
    }

    >h1,
    >.h1,
    >h2,
    >.h2 {
      margin-top: 0;

      @include media("<desktop") {
        order: -1;
      }

      @include media(">=desktop") {
        margin-bottom: 0;
      }

      &:not(:first-child) {
        margin-top: 0;

        @include media(">=desktop") {
          padding-top: rem-calc(20);
        }
      }

      &+h4,
      &+.h4 {
        @include media("<desktop") {
          order: -1;
          padding: rem-calc(8 0 8 0);
        }
      }
    }

  }

  .section-heading {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    padding-bottom: rem-calc(17);
    position: relative;
    z-index: 6;

    @include media(">=desktop") {
      padding-bottom: rem-calc(32);
    }

    .news-section & {
      @include media(">=desktop") {
        padding-bottom: rem-calc(10);
      }
    }

    .buttons-section & {
      @include media(">=desktop") {
        padding-top: rem-calc(8);
      }
    }

    &.filter-heading {
      padding-bottom: rem-calc(27);

      @include media(">=tablet") {
        padding-bottom: rem-calc(26);
        min-height: rem-calc(64);
      }

      @include media(">=desktop") {
        padding-bottom: rem-calc(26);
        min-height: rem-calc(64);
      }

      .aside-column & {
        padding-bottom: rem-calc(22);
      }

      &.site-page-heading {
        @include media(">=tablet") {
          margin-bottom: -3.5rem;
        }
      }
    }

    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
      margin-bottom: 0;
      padding-right: rem-calc(18);

      @include media(">=desktop") {
        padding-right: rem-calc(26);
      }
    }
  }

  .section {
    .card-hero-section+& {
      @include media(">=tablet") {
        padding-top: rem-calc(68);
      }

      @include media(">=desktop") {
        padding-top: rem-calc(92);
      }
    }
  }

  .four-columns {
    column-count: 2;
    column-gap: rem-calc(20);

    @include media(">=desktop") {
      column-count: 3;
    }

    @include media(">=widescreen") {
      column-count: 4;
    }

    >li,
    p {
      break-inside: avoid;
    }
  }

  .three-columns {
    column-count: 2;
    column-gap: rem-calc(20);

    @include media(">=desktop") {
      column-count: 3;
    }

    >li,
    p {
      break-inside: avoid;
    }
  }

  .article-block {
    h2 {
      margin-bottom: 0.2em; // 8px
    }

    p {
      &:not(:last-child) {
        margin-bottom: 0.5556em; // 10px
      }
    }
  }

  .side-block {
    border-top: rem-calc(2) solid $primary-lighter;
    padding-top: rem-calc(28);
    padding-bottom: rem-calc(28);

    &:not(:last-child) {
      padding-bottom: rem-calc(54);
    }

    .content {
      font-size: rem-calc(14);
      line-height: 1.58; // 22px

      h5 {
        font-size: 1.4286em; // 20px
        line-height: 1.2; // 24px
      }
    }

    h5 {
      font-size: 1.1765; // 20px
      line-height: 1.2; // 24px
      letter-spacing: normal;
      margin-bottom: rem-calc(12);
    }

    p:not(:last-child) {
      margin-bottom: rem-calc(10);
    }
  }

  .meta-info {
    font-size: rem-calc(14);
    line-height: 1.6923; // 22px
    letter-spacing: -0.0308em; // -.4px

    .page-heading & {
      padding-top: rem-calc(28);

      @include media(">=desktop") {
        padding-top: 0;
        margin-bottom: rem-calc(12);
      }
    }

    >li {
      display: inline;

      &:not(:last-child) {
        &:after {
          @include size(1px, rem-calc(10));
          content: "";
          background: currentColor;
          display: inline-block;
          vertical-align: top;
          font-size: rem-calc(10);
          line-height: 1;
          margin: rem-calc(6 4 0 8);

          @include media("<tablet") {
            margin: rem-calc(6 10 0 10);
          }
        }
      }
    }
  }

  .remove-if-empty:empty {
    display: none;
  }

  ul {
    li {
      a {
        i.icon {
          &:hover {
            color: $link-hover;
          }
        }
      }
    }
  }

  .content {
    .columns {
      &.is-block {
        .column {

          ul,
          ol {
            li {
              a {
                color: $blue !important;

                &:hover {
                  color: $pink-dark !important;

                  em,
                  strong,
                  i,
                  b {
                    color: $pink-dark !important;
                  }
                }

                em {
                  color: $blue !important;

                  &:hover {
                    color: $pink-dark !important;
                  }
                }

                b {
                  color: $blue !important;

                  &:hover {
                    color: $pink-dark !important;
                  }
                }

                i {
                  color: $blue !important;

                  &:hover {
                    color: $pink-dark;
                  }
                }

                strong {
                  color: $blue !important;

                  &:hover {
                    color: $pink-dark;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .block_paragraph {

    ul,
    ol {
      li {
        a {
          color: $blue !important;

          &:hover {
            color: $pink-dark !important;

            em,
            strong,
            i,
            b {
              color: $pink-dark !important;
            }
          }

          em {
            color: $blue !important;

            &:hover {
              color: $pink-dark !important;
            }
          }

          b {
            color: $blue !important;

            &:hover {
              color: $pink-dark !important;
            }
          }

          i {
            color: $blue !important;

            &:hover {
              color: $pink-dark;
            }
          }

          strong {
            color: $blue !important;

            &:hover {
              color: $pink-dark;
            }
          }
        }
      }
    }
  }

  .is-4-desktop {
    .side-column {

      &.image-md,
      &.image-lg,
      &.image-xl {
        width: 100% !important;
      }

      figure {
        margin: 0 !important;
      }
    }
  }

  .block_paragraph.column,
  .block_featured_text {

    &.is-4,
    &.is-4-desktop {
      .is-clearfix {
        .side-column {

          &.image-md,
          &.image-lg,
          &.image-xl {
            width: 100% !important;
          }

          &.is-md,
          &.is-lg {
            width: 100% !important;
          }
        }
      }

      .card,
      .second-card {

        .card-content,
        .second-card-content {
          .content {
            .side-column {

              &.image-md,
              &.image-lg {
                width: 100% !important;
              }

              &.is-md,
              &.is-lg {
                width: 100% !important;
              }
            }
          }
        }
      }
    }

    &.is-6,
    &.is-6-desktop {
      .is-clearfix {
        .side-column {

          &.image-lg,
          &.image-xl {
            width: 100% !important;
          }

          &.is-lg {
            width: 100% !important;
          }
        }
      }
    }

    &.is-4-desktop,
    &.is-4 {
      .side-column {
        &.is-centered {

          &.is-md,
          &.is-lg {
            width: 100% !important;
          }
        }
      }
    }
  }

  .block_featured_text {
    .card {
      .card-content {
        .content {

          ul,
          ol {
            li {
              a {
                // color: $blue !important;

                &:hover {
                  color: $pink-dark !important;

                  em,
                  strong,
                  i,
                  b {
                    // color: $pink-dark !important;
                  }
                }

                em {
                  // color: $blue !important;

                  &:hover {
                    color: $pink-dark !important;
                  }
                }

                b {
                  // color: $blue !important;

                  &:hover {
                    color: $pink-dark !important;
                  }
                }

                i {
                  // color: $blue !important;

                  &:hover {
                    color: $pink-dark;
                  }
                }

                strong {
                  // color: $blue !important;

                  &:hover {
                    color: $pink-dark;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .content {
    .is-clearfix {

      ul,
      ol {
        li {
          a {
            color: $blue !important;

            &:hover {
              color: $pink-dark !important;

              em,
              strong,
              i,
              b {
                color: $pink-dark !important;
              }
            }

            em {
              color: $blue !important;

              &:hover {
                color: $pink-dark !important;
              }
            }

            b {
              color: $blue !important;

              &:hover {
                color: $pink-dark !important;
              }
            }

            i {
              color: $blue !important;

              &:hover {
                color: $pink-dark;
              }
            }

            strong {
              color: $blue !important;

              &:hover {
                color: $pink-dark;
              }
            }
          }
        }
      }
    }
  }

  div.column.is-pulled-left,
  div.column.is-pulled-right {
    padding-bottom: 0 !important;

    margin: {
      top: 0 !important;
      bottom: 0 !important;
    }

    .aside-block.block {
      margin-bottom: rem-calc(40) !important;
    }

    .landing-page__block {
      margin: {
        top: 0px !important;
        bottom: 0px;
      }
    }

    @include until("mobile") {
      padding-top: rem-calc(20) !important;
    }

    @include from("tablet") {
      padding-top: 0 !important;
    }

    &.is-4-tablet,
    &.is-4-desktop,
    &.is-4 {
      .block_video_upload {
        .side-column {

          &.is-md,
          &.is-lg {
            width: 100%;
          }
        }
      }
    }
  }

  .content-column.column.is-pulled-right,
  .content-column.column.is-pulled-left {
    .landing-page__block {
      @include media("<=tablet") {
        padding: {
          top: 0 !important;
          bottom: 0 !important;
        }

        margin: {
          top: 0px !important;
          bottom: rem-calc(40) !important;
        }
      }
    }
  }

  .content-column.column.px-0 {
    >.columns {
      @include media("<=tablet") {
        margin-top: -1rem !important;
      }
    }

    .landing-page__block {
      @include media("<=tablet") {
        margin-bottom: rem-calc(40);

        padding: {
          top: 0;
          bottom: 0;
        }
      }
    }
  }

  .block_chemistry_uses {
    .card-content {
      .columns {
        .column:not(:first-child) {
          @include media("<tablet") {
            padding: {
              top: 0 !important;
              bottom: 0 !important;
            }

            margin-bottom: 0.75rem;
          }
        }
      }
    }
  }

  .block_richtext {
    .ez-embed-type-image {
      line-height: 0 !important;
    }
  }

  .block_file_download_email {
    .card {
      .content {
        a {
          i.icon {
            &:hover {
              color: inherit !important;
            }
          }
        }
      }
    }
  }

  .block_richtext {
    .side-column {
      .card.is-horizontal {
        .card-content {
          .content {
            a.button {
              i.icon {
                &:hover {
                  color: inherit !important;
                }
              }
            }
          }
        }
      }
    }
  }

  form {
    &:not(.search-sort-form, .search-form) {

      input[type="submit"],
      input[type="button"],
      input[type="reset"],
      input[type="file"],
      button[type="submit"],
      button[type="button"],
      button[type="reset"],
      button[type="file"] {
        color: $white !important;

        &:hover {
          color: $white !important;
        }

        i.icon {
          color: inherit !important;

          &:hover {
            color: inherit !important;
          }
        }
      }
    }
  }

  .block_stat_numeric,
  .block_stat_chart {
    ul.social-networks {
      li {
        a {
          &:hover {
            color: $pink-dark !important;
          }

          i.icon {
            &:hover {
              color: $pink-dark !important;
            }
          }
        }
      }
    }
  }

  .block_hero {
    .card-content {
      a.button {
        * {
          font-weight: 500 !important;
        }

        i.icon {
          &:hover {
            color: inherit !important;
          }
        }
      }
    }
  }

  /**** temporary disabled
.page-heading + .content {
	.is-clearfix {
		@include media('<tablet') {
			display: flex;
			flex-direction: column;

			address.author {
				order: 0 !important;
			}

			.side-column {
				order: 1 !important;
			}

			:not(.side-column), p {
				order: 3;
			}
		}
	}
}
****/

  .page-heading {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      word-break: break-word;
    }

    @include media("<tablet") {
      .social-media {
        padding-top: rem-calc(10);
      }
    }
  }

  .columns {
    p {
      word-break: break-word;

      a {
        word-break: break-word;
      }
    }
  }

  .block_document_embed iframe {
    @include media("<tablet") {
      height: 450px !important;
    }
  }

  .iframe--document-embed {
    @include media("<tablet") {
      height: 450px !important;
    }
  }

  .layout-404 {
    @include media("<tablet") {
      img {
        border-radius: rem-calc(15);
      }
    }

    &.grid-container {
      display: grid;
      grid-template-columns: auto auto;

      @include media("<tablet") {
        gap: rem-calc(15);
      }

      @include media(">=tablet") {
        gap: rem-calc(40);
      }

      .grid-item {
        &.item-1 {
          @include media("<tablet") {
            grid-column: 1 / span 2;
            grid-row: 1 / 2;
          }
        }

        &.item-2 {
          grid-column: 2 / span 2;
          grid-row: 1 / span 2;

          @include media("<tablet") {
            grid-column: 1 / span 2;
            grid-row: 2 / 3;
          }
        }

        &.item-3 {
          @include media("<tablet") {
            grid-column: 1 / span 2;
            grid-row: 3 / 4;
          }

          .button-list-search {
            display: flex;
            flex-wrap: wrap;

            & * {
              margin: {
                top: rem-calc(10);
                right: rem-calc(10);
              }
            }

            a.button {
              white-space: normal;
            }
          }
        }
      }
    }
  }

  .block_navigator_full {
    @include media("<tablet") {
      .section-heading {
        display: block !important;
      }
    }
  }

  .mt-4-only-tablet {
    @include tablet {
      margin-top: 1rem !important;
    }
  }

  hr {
    height: 0.125rem;
    background-color: #4fa3ae;
  }

  .container-full-width {
    @include media(">=desktop") {
      max-width: 1920px !important;
    }
  }

  .second-container {
    max-width: 1640px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: rem-calc(14);

    @include media(">=tablet") {
      gap: rem-calc(40);
    }
  }

  .second-section {
    padding: 3rem 0;

    .section-heading {
      padding: 0;

      @include media("<tablet") {
        justify-content: space-between;
      }

      h2 {
        font-size: rem-calc(23);
        line-height: 1.65;
        font-weight: 600;
        letter-spacing: normal;

        @include media(">=tablet") {
          font-size: rem-calc(28);
          line-height: 1.3;
        }

        @include media(">=desktop") {
          font-size: rem-calc(32.65);
        }
      }

      .link-view {
        font-size: rem-calc(17);
        line-height: 1.25;
        margin: 0;
        padding-bottom: 0.25rem;
        letter-spacing: -0.5px;

        @include media("<tablet") {
          font-size: rem-calc(15);
          line-height: 1.17;
          align-self: center;
        }
      }
    }
  }

  .main-section {
    padding: 3rem 1.25rem;

    @include media(">desktop") {
      display: flex;
      justify-content: center;
    }

    &>.container:not(.is-max-desktop):not(.is-max-widescreen) {
      @include media(">desktop") {
        margin: 0 80px;
        width: 1px;
      }
    }
  }

  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1640px;
  }

  .columns.is-multiline {
    &>.is-fullwidth {
      &:first-child {
        padding-top: 0;
      }

      &:first-last {
        padding-bottom: 0;
      }

      &+.is-fullwidth {
        margin-top: -40px;
      }
    }
  }

  .full-width {
    position: relative;
    z-index: 0;
    overflow: visible;

    &::before {
      content: "";
      z-index: -1;
      width: 100vw;
      height: 100%;
      display: block;
      background: inherit;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -50vw;
    }
  }

  %list-indent {
    .is-clearfix {

      &>ol,
      &>ul,
      &>li,
      &>ol li,
      &>ul li {
        padding: 0;
        margin: 0;
      }

      &>ol,
      &>ul {
        margin-bottom: rem-calc(20);
      }

      &>ol,
      &>ol ol {
        padding-left: 2.75rem;
      }

      &>ul,
      &>ul ul {
        padding-left: 1.75rem;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  .block_paragraph {
    @extend %list-indent;

    .is-clearfix {

      &>ul,
      &>ul ul {
        padding-left: 1.5rem;

        & li,
        ul li {
          padding-left: 1.375rem;
        }

        & li ul {
          &>li {
            &::before {
              border: 1px solid currentColor;
              background-color: #fff;
            }

            & ul {
              &>li::before {
                border-radius: 0%;
                background-color: currentColor;
              }
            }
          }
        }
      }
    }
  }

  .block_paragraph {
    @extend %list-indent;

    .is-clearfix {

      &>ol,
      &>ol ol {
        padding-left: 2.75rem;

        li {
          text-indent: rem-calc(-20);
        }
      }
    }
  }

  *:is([class*="termly-styles-main"], [class*="termly-styles-modal"]) {
    table {
      thead {
        white-space: nowrap;
      }
    }

    button[class*="termly-styles-button"] {
      line-height: 1.375;
      font-weight: 600 !important;
      width: auto;
      border-radius: 3px;
      height: auto !important;
      padding: 0.565rem 1.5rem !important;
    }
  }

  [class*="termly-styles-termly-banner"] {
    margin: 0;
    border-radius: 0;
    width: 100%;
    max-width: 100% !important;
    padding: 2.5em 1.25rem !important;
    display: flex;
    justify-content: center;

    [class*="termly-styles-main"] {
      max-width: 1640px;
      width: 100%;

      @media (min-width: 1025px) {
        width: 1px;
        flex-grow: 1;
        margin: 0 80px;
      }
    }
  }

  @media (max-width: 767px) {
    [class*="termly-styles-termly-banner"] {
      font-size: 14px !important;
    }

    [class*="termly-styles-message"] {
      margin-bottom: 20px !important;
    }

    [class*="termly-styles-buttons"] {
      padding: 20px 0 0 !important;
    }
  }

  /* Current colors */

  .has-text-white {
    color: $white !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $white;
    }

    a {
      &.link-more {
        color: $pink !important;

        &:hover {
          color: $pink-dark !important;
        }
      }
    }
  }

  .is-outlined-white,
  .has-outlined-white {
    border: 1px solid $white;
  }

  .has-background-white {
    background-color: $white;
  }

  .has-text-off-white {
    color: $off-white !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $off-white;
    }
  }

  .has-background-off-white {
    background-color: $off-white;
  }

  // ACTUALIZADO
  .has-text-primary-lighter,
  .has-text-teal {
    color: $primary-lighter !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $primary-lighter;
    }
  }

  .has-background-primary-lighter,
  .has-background-teal {
    background: $primary-lighter;
  }

  .has-text-medium-teal {
    color: $medium-teal !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $medium-teal;
    }
  }

  .has-background-medium-teal {
    background-color: $medium-teal;
  }

  .has-text-primary-lightest,
  .has-text-pale-blue {
    color: $primary-lightest !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $primary-lightest;
    }
  }

  .has-background-primary-lightest,
  .has-background-pale-blue {
    background: $primary-lightest;
  }

  .has-text-grey-light-alt {
    color: $grey-light-alt !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $grey-light-alt;
    }
  }

  .has-background-grey-light-alt {
    background: $grey-light-alt;
  }

  .has-text-grey {
    color: $grey !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $grey;
    }
  }

  .has-background-grey {
    background: $grey;
  }

  .has-text-magenta,
  .has-text-pink,
  .has-text-link {
    color: $pink !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $pink;
    }
  }

  .has-background-magenta,
  .has-background-pink,
  .has-background-link {
    background: $pink;
  }

  .has-text-info,
  .has-text-dark-blue {
    color: $blue-darkest !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $blue-darkest;
    }
  }

  .has-background-info,
  .has-background-dark-blue {
    background-color: $blue-darkest !important;
  }

  .has-outlined-info,
  .has-outlined-dark-blue {
    border: 1px solid $blue-darkest;
  }

  .has-text-medium-blue {
    color: $blue !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $blue;
    }
  }

  .has-background-medium-blue {
    background-color: $blue;
  }

  .has-text-black {
    color: $black !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $black;
    }
  }

  .has-background-black {
    background: $black;
  }

  .has-text-red {
    color: $custom-red !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $custom-red;
    }
  }

  .has-background-red {
    background-color: $custom-red;
  }

  .has-text-yellow {
    color: $custom-yellow !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $custom-yellow;
    }
  }

  .has-background-yellow {
    background: $custom-yellow;
  }

  .has-text-green {
    color: $custom-green !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $custom-green;
    }
  }

  .has-background-green {
    background: $custom-green;
  }

  .has-background-midnight-blue,
  .has-background-blue {
    background: $midnight-blue;
  }

  .has-outlined-midnight-blue,
  .has-outlined-blue {
    border: 1px solid $midnight-blue;
  }

  .has-text-midnight-blue,
  .has-text-blue {
    color: $midnight-blue !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $midnight-blue;
    }
  }

  .has-background-intense-red {
    background: $intense-red;
  }

  .has-outlined-intense-red {
    border: 1px solid $intense-red;
  }

  .has-text-intense-red {
    color: $intense-red !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $intense-red;
    }
  }

  .has-background-bright-orange,
  .has-background-orange {
    background: $bright-orange;
  }

  .has-outlined-bright-orange,
  .has-outlined-orange {
    border: 1px solid $bright-orange;
  }

  .has-text-bright-orange,
  .has-text-orange {
    color: $bright-orange !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $bright-orange;
    }
  }

  .has-background-light-pink,
  .has-background-pale-pink {
    background: $light-pink;
  }

  .has-outlined-light-pink,
  .has-outlined-pale-pink {
    border: 1px solid $light-pink;
  }

  .has-text-light-pink,
  .has-text-pale-pink {
    color: $light-pink !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $light-pink;
    }
  }

  .has-background-dark-purple,
  .has-background-purple {
    background: $dark-purple;
  }

  .has-outlined-dark-purple,
  .has-outlined-purple {
    border: 1px solid $dark-purple;
  }

  .has-text-dark-purple,
  .has-text-purple {
    color: $dark-purple !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $dark-purple;
    }
  }

  // ACTUALIZADO
  .has-background-light-blue,
  .has-background-medium-pale-blue {
    background: $light-blue;
  }

  .has-outlined-light-blue,
  .has-outlined-medium-pale-blue {
    border: 1px solid $light-blue;
  }

  .has-text-light-blue,
  .has-text-medium-pale-blue {
    color: $light-blue !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $light-blue;
    }
  }

  // ACTUALIZADA 
  .has-background-aqua-light,
  .has-background-pela-teal {
    background: $aqua-light;
  }

  .has-outlined-aqua-light,
  .has-outlined-pela-teal {
    border: 1px solid $aqua-light;
  }

  .has-text-aqua-light,
  .has-text-pela-teal {
    color: $aqua-light !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $aqua-light;
    }
  }

  .has-background-lime-green,
  .has-background-lime {
    background: $lime-green;
  }

  .has-outlined-lime-green,
  .has-outlined-lime {
    border: 1px solid $lime-green;
  }

  .has-text-lime-green,
  .has-text-lime {
    color: $lime-green !important;

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span i.icon,
      a em,
      a strong,
      a i,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $lime-green;
    }
  }

  .has-text-medium-teal {
    & {
      color: $medium-teal !important;
    }

    *:not(a,
      a.button.is-medium.is-link span,
      a.button.is-medium.is-link span,
      a em,
      a strong,
      a b,
      form.subscribe-form .button.is-link.is-small) {
      color: $medium-teal;
    }
  }
}

.is-two-columns-center {
  margin-left: calc(33.333333% / 2) !important;
  margin-right: calc(33.333333% / 2) !important;
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0.05;
}

.btn {
  display: inline-flex;
  gap: 7px;
  align-items: center;
  padding: 10px 17px;
  border: 1px solid currentColor;
  border-radius: 2em;
}

#app #main a {
  &:hover {
    color: $pink !important;

    * {
      color: $pink !important;
    }
  }
}

.modal {
  display: flex;
  pointer-events: none;
  opacity: 0;
  z-index: 999;
  transition: all ease 0.4s;

  &.is-active {
    pointer-events: auto;
    opacity: 1;
  }

  &-background {
    cursor: pointer;
  }
}