.hero-carousel {
  position: relative;

  width: 100vw !important;
  margin-left: 50%;
  transform: translateX(-50%);
  // @include media(">=widescreen") {
  //   //margin-top: rem-calc(48);
  // }

  .hero-body {
    padding: 0;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    position: relative;

    @include media(">=tablet") {
      padding: rem-calc(63 20);
      min-height: rem-calc(600);
      flex-direction: row;
    }

    .columns {
      justify-content: flex-end;
    }

    @include media('<tablet') {
      margin: {
        top: -3rem;
        left: -1.5rem;
        right: -1.5rem;
      }
    }
  }

  .is-left-alignment {

    .columns {
      justify-content: flex-start;
    }

    .hero-bg {
      background-position: 78% 50%;
      z-index: 0;

      @include media(">=phone") {
        background-position: 83% 50%;
      }

      @include media(">=tablet") {
        background-position: 60% 50%;
      }
    }
  }

  .container {
    position: static;
  }

  .hero-bg {
    position: static;
    background-repeat: no-repeat;
    background-position: 16% 50%;
    background-size: cover;
    width: 100%;
    padding-bottom: 71%;
    min-height: rem-calc(260);
    margin-bottom: rem-calc(-40);
    z-index: -1;

    @include media(">=tablet") {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
      min-height: auto;
    }
  }

  .card {
    line-height: 1.53; // 17px/26px
    font-weight: $weight-medium;
    letter-spacing: normal;
    border-radius: $radius-xlarge $radius-xlarge 0 0;

    @include media('<=tablet') {
      border-radius: 0;
    }

    @include media(">tablet") {
      border-radius: $radius-xlarge;
    }

    @include media(">=desktop") {
      font-size: 1.1176em; // 19px
      line-height: 1.48; // 28px
    }

    .card-content {
      padding: rem-calc(25);

      @include media(">=phone") {
        padding: rem-calc(43 45);
      }

      @include media(">=desktop") {
        padding: rem-calc(41 46 45);
      }
    }

    .content {
      margin-bottom: rem-calc(14);

      @include media(">=desktop") {
        margin-bottom: rem-calc(28);
      }
    }

    h1 {
      font-size: 1.6471em; // 28px
      line-height: 1.214; // 34px
      margin-bottom: 0.3571em; // 10px
      letter-spacing: normal;

      @include media(">=desktop") {
        font-size: 2.3158em; // 44px
        line-height: 1.1; // 48px
        margin-bottom: 0.4091em; // 18px
      }
    }

    .button {
      align-self: flex-start;
    }
  }
}

.block_hero {
  .card.card-hero {
    @include media('>tablet') {
      margin-top: rem-calc(48);
    }

    @include media('<=tablet') {
      margin-top: rem-calc(45);
    }

    @include media('<=phone') {
      margin-top: rem-calc(10);
      margin-bottom: rem-calc(10);
    }
  }
}

.block_hero {
  .hero {
    &.hero-carousel {
      section.slide {
        .card {
          .card-content {
            .hero-body {
              a.button.is-link {
                color: $white !important;
              }
            }
          }
        }
      }
    }
  }
}