.card {
  //font-weight: $weight-semibold;
  font-weight: 500;
  line-height: 1.4118; // 17px/24px
  letter-spacing: -0.0294em; // -0.5px
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &-is-not-rounded {
    @include media("<=tablet") {
      border-radius: 0;
    }
  }

  &.is-flex {
    &.is-flex-direction-column {
      &.has-background-white {
        &.is-outlined-white {
          .card-content {
            padding-left: 0;
            margin-left: 0;
          }
        }
      }
    }
  }

  @for $i from 1 through 5 {

    @at-root .column.is-#{$i} & .card-content .columns,
    .column.is-#{$i}-desktop & .card-content .columns {
      display: block;

      .column {
        width: 100%;
      }
    }
  }

  .card-image {
    width: 100%;
    flex-shrink: 0;

    figure {
      margin-left: 0;
      margin-right: 0;
    }

    img {
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    word-break: break-word;

    @include media(">=tablet", "<desktop") {
      padding: rem-calc(20);
      word-wrap: break-word;
      hyphens: auto;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
    }
  }

  .partner-logo {
    margin-bottom: rem-calc(10);

    @include media(">=desktop") {
      margin-bottom: rem-calc(13);
    }

    img {
      height: 50px;
      width: auto;
    }
  }

  h4 {
    margin-bottom: 0.9091em; // 20px
  }

  h5 {
    letter-spacing: normal;
    margin-bottom: 0.4091em; // 9px
  }

  p {
    &:not(:last-child) {
      margin-bottom: 1.4118em;
    }

    +ul {
      margin-top: -0.5882em; // -10px
    }
  }

  ul {
    font-weight: 500;
    //font-weight: $weight-medium;

    >li {
      &:before {
        top: 0.5em; // 8px
      }
    }
  }

  .link-more {
    margin-top: auto;
    letter-spacing: normal;

    @include media(">=desktop") {
      margin-bottom: rem-calc(-8);
    }
  }

  .chart-holder {
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(20);

    img {
      vertical-align: top;
    }
  }

  .content {
    &.is-size-smaller {
      letter-spacing: normal;

      h5 {
        font-size: 1.4667em; // 22px
        line-height: 1.0909; // 24px
      }
    }
  }

  .content-wrap {
    width: 100%;
    display: flex;
    padding-bottom: rem-calc(15);

    .icon-holder {
      flex-shrink: 0;
      width: rem-calc(72);

      .icon {
        @include size(auto);
        font-size: rem-calc(58);
        line-height: 1;
        font-weight: $weight-normal;
        margin-left: rem-calc(-6);
      }
    }

    .text-holder {
      flex-grow: 1;
    }
  }

  &.is-horizontal {
    flex-direction: row;
  }

  &.is-horizontal:not(.card-news) {
    @include media(">=desktop") {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .card-image {
      @include media(">=desktop") {
        display: flex;
        width: 50%;
      }

      .image {
        width: 100%;

        @include media(">=desktop") {
          padding-top: 0;
        }
      }
    }

    .card-content {
      @include media(">=desktop") {
        width: 50%;
        flex-grow: 1;
        min-height: rem-calc(263);
      }

      &:not(.content-promo) {
        @include media(">=widescreen") {
          padding-left: rem-calc(43);
        }
      }
    }

    .content {
      font-size: 0.9412em; // 16px
      line-height: 1.375; // 22px
      letter-spacing: -0.0312em; // -.5px

      @include media(">=desktop") {
        padding-top: rem-calc(20);
      }

      &:not(:last-child) {
        margin-bottom: rem-calc(12);

        @include media(">=desktop") {
          margin-bottom: rem-calc(50);
        }
      }

      .link-more {
        font-size: 1em;
      }
    }

    h4 {
      font-size: 1.375em; // 22px
      line-height: 1.1818; // 26px
      margin-bottom: 0.6364em; // 14px

      @include media(">=desktop") {
        font-size: 1.875em; // 30px
        line-height: 1.1333; // 34px
        letter-spacing: -0.0167em; // -.5px
        margin-bottom: 0.5em; // 15px
      }
    }

    p {
      +ul {
        margin-top: -0.6875em; // -11px
      }
    }

    ul {
      >li {
        &:before {
          top: 0.4375em; // 7px
        }
      }
    }

    ul.is-li-vertical-space-small {
      >li {
        +li {
          margin-top: rem-calc(3);
        }
      }
    }

    &.is-full-width {
      border-top-right-radius: rem-calc(15);
      border-bottom-right-radius: rem-calc(15);

      .card-image {
        @include media(">=tablet") {
          width: 50%;
          display: flex;
          padding-top: 0;
        }

        @include media(">=desktop") {
          width: 32.7%;
        }
      }

      .card-content {
        @include media(">=tablet") {
          width: 50%;
          flex-grow: 1;
        }

        @include media(">=desktop") {
          width: 67.3%;
          min-height: 1px;
          padding-top: rem-calc(30);
          padding-bottom: rem-calc(25);
        }

        @include media(">=fullhd") {
          padding-left: rem-calc(48);
        }
      }

      .content {
        padding-top: 0;
        font-size: 1em;
        line-height: 1.4118; // 24px
      }

      h5 {
        margin-bottom: 0.3182em; // 7px
      }

      ul {
        >li {
          &:before {
            top: 0.4706em; // 8px
          }
        }
      }

      .two-columns {
        @include media(">=desktop") {
          column-count: 2;
          column-gap: rem-calc(32);
        }

        p,
        ul {
          @include media(">=desktop") {
            break-inside: avoid;
          }
        }

        p+ul {
          @include media(">=desktop") {
            margin-top: 0;
          }
        }

        ul {
          padding-top: rem-calc(5);
        }
      }
    }
  }

  &:not(.is-horizontal) {
    .card-image {
      +.card-content {
        @include media("<tablet") {
          padding-top: rem-calc(26);
        }

        @include media(">=desktop") {
          padding-top: rem-calc(23);
        }
      }
    }
  }

  &.is-horizontal-mobile {
    @include media("<tablet") {
      flex-direction: row;
      flex-wrap: nowrap;
      padding-top: 0;
      padding-bottom: rem-calc(3);
    }

    .card-image {
      @include media("<tablet") {
        width: 27.4667%;
        border-radius: rem-calc(3);
        margin: rem-calc(5 23 0 0);
        align-self: flex-start;
      }

      +.card-content {
        @include media("<tablet") {
          padding-top: 0;
        }
      }
    }

    .card-content {
      @include media("<tablet") {
        padding: 0;
      }
    }
  }

  &.is-fully-clickable {
    &:hover {
      filter: brightness(0.95);
      cursor: pointer;
    }
  }

  &-newsletter {
    margin-top: rem-calc(15);

    @include media(">=tablet") {
      margin-top: 0;
    }

    .card-content {
      padding-top: rem-calc(32);
      padding-bottom: rem-calc(32);

      @include media(">=tablet") {
        padding-bottom: rem-calc(28);
      }
    }

    .content {
      &:not(:last-child) {
        margin-bottom: rem-calc(16);
      }
    }

    h5 {
      margin-bottom: 0.6818em; // 15px

      @include media(">=tablet") {
        margin-bottom: 0.5909em; // 13px
      }
    }
  }

  &-news {
    line-height: 1.53; // 26px
    font-weight: $weight-medium;
    padding: rem-calc(10) rem-calc(16);
    position: relative;
    overflow: visible;
    flex-direction: column;
    background-color: transparent;
    @include animate(all, 0.15s, ease-out);

    @include media(">=desktop") {
      padding-top: rem-calc(17);
    }

    &:not(:last-child) {
      margin-bottom: rem-calc(20);
    }

    .news-articles & {
      color: #6a6a6a;
    }

    // This breaks the behavior of the cards, for the moment it will be deactivated
    &.highlight-carousel-card:hover {
      background-color: #fff;
      box-shadow: 0 rem-calc(10) rem-calc(30) rgba($black, 0.13) !important;

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }

    &.no-hover-shadow:hover,
    &.no-hover-shadow:hover.is-shadowless:hover {
      box-shadow: none !important;
    }

    .card-image {
      flex-shrink: 0;
      border-radius: rem-calc(15 15 0 0);
      overflow: hidden;
      z-index: 1;
    }

    .card-content {
      padding-top: rem-calc(22);
      padding-bottom: rem-calc(22);
      flex-grow: 1;
      padding-left: 0;
      padding-right: 0;
      z-index: 1;

      h5 {
        a {
          font-size: 17px;
          font-weight: 500;
          line-height: 1.3;

          @include media(">=desktop") {
            font-size: rem-calc(23);
          }
        }
      }
    }

    .meta {
      display: flex;

      .date {
        margin-left: auto;
        margin-bottom: rem-calc(4);
        padding-left: rem-calc(10);
      }

      .category-label {
        margin-bottom: rem-calc(4);
        max-width: 75%;
      }
    }

    h4,
    .h4 {
      margin-bottom: 0.41em;
      letter-spacing: normal;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 0.9em; // 20px

        @include media(">=tablet") {
          margin-bottom: 0.9em; // 20px
        }
      }

      +.tags {
        padding-top: 0;
      }
    }

    .tags {
      padding-top: rem-calc(9);
    }

    &.is-horizontal-mobile {
      @include media("<tablet") {
        font-size: 0.7647em; // 13px
        line-height: 1.6923; // 22px
      }

      &:before {
        @include media("<tablet") {
          display: none;
        }
      }

      .meta {
        @include media("<tablet") {
          display: block;
        }

        .date {
          @include media("<tablet") {
            padding-left: 0;
          }
        }
      }

      .category-label {
        @include media("<tablet") {
          display: none;
        }
      }

      .content {
        @include media("<tablet") {
          display: flex;
          flex-direction: column;
        }
      }

      h5 {
        @include media("<tablet") {
          // font-size: 1em;
          font-size: 1.3077em; // 17px
          line-height: 1.4118; // 24px;
          font-weight: $weight-semibold;
          margin-bottom: 0.1765em; // 3px
          order: -1;
        }
      }
    }

    &.is-horizontal {
      flex-direction: row;
      flex-wrap: nowrap;
      padding-top: 0;
      padding-bottom: rem-calc(3);

      .posts & {
        &:not(:last-child) {
          margin-bottom: rem-calc(40);

          &.no-tags {
            margin-bottom: rem-calc(31);
          }
        }
      }

      .posts.is-bordered-left>& {
        padding-left: rem-calc(33);
        position: relative;

        @include media(">=tablet") {
          padding-left: rem-calc(23);
        }

        @include media(">=desktop") {
          padding-left: rem-calc(33);
        }

        &:hover,
        &.active {
          &:after {
            background: $link;
          }
        }

        &:after {
          @include animate(background, 0.15s, ease-out);
          content: "";
          position: absolute;
          left: 0;
          top: rem-calc(5);
          bottom: rem-calc(5);
          width: rem-calc(7);
          background: transparent;
        }

        .meta {
          order: -1;
        }
      }

      .news-cards-container & {
        &:not(:last-child) {
          margin-bottom: rem-calc(12);
        }

        h3 {
          order: initial;
          letter-spacing: -0.0294em; // -.5px
        }

        .meta {
          .date {
            margin-bottom: 0;
          }
        }
      }

      .news-articles & {
        padding-top: rem-calc(18);

        &:not(:first-child) {
          margin-top: 0;
        }

        &:not(:last-child) {
          margin-bottom: 0;
          padding-bottom: rem-calc(18);
        }
      }

      &:before {
        display: none;
      }

      .card-image {
        width: 28.5%;
        margin: rem-calc(5 32 0 0);
        align-self: flex-start;
        flex-shrink: 0;
        border-radius: 0;

        img {
          border-radius: 0;
        }

        +.card-content {
          padding-top: 0;
        }
      }


      .card-content {
        padding: 0;
        flex-grow: 1;
      }

      .meta {
        display: block;

        .date {
          padding-left: 0;
          font-size: rem-calc(15);
          line-height: 2.0473;
          font-weight: normal;
          color: #757575;
        }
      }

      .tags {
        padding-top: rem-calc(6);

        .tag {
          padding: rem-calc(6 12);
          border-radius: rem-calc(6) !important;
          font-family: Montserrat;
          font-size: rem-calc(10);
          line-height: 1.2;

          &:hover {
            color: white !important;
          }

          &:not(:last-child) {
            margin-right: rem-calc(18);
          }
        }
      }

      .category-label {
        display: none;
      }

      .content {
        display: flex;
        flex-direction: column;
      }

      h3 {
        font-size: rem-calc(18);
        font-weight: 500;
        line-height: 1.3;
        margin-top: 0;
        margin-bottom: rem-calc(4);
        order: -1;
        @media(min-width:768px){
          font-size: rem-calc(23);
        }
      }
    }

    &.is-horizontal-desktop {
      padding-top: 0;

      @include media(">=tablet") {
        flex-direction: row;
        flex-wrap: nowrap;
      }

      &:not(:last-child) {
        margin-bottom: rem-calc(10);

        @include media(">=tablet") {
          margin-bottom: rem-calc(46);
        }
      }

      &:before {
        display: none;
      }

      .card-image {
        border-radius: 0;

        img {
          border-radius: 0;
        }

        @include media(">=tablet") {
          width: 38.5%;
          margin: rem-calc(5 23 0 0);
          align-self: flex-start;
          flex-shrink: 0;
        }

        @include media(">=desktop") {
          margin-right: rem-calc(42);
        }

        +.card-content {
          padding-top: rem-calc(20);
          padding-bottom: rem-calc(20);

          @include media(">=tablet") {
            padding-top: 0;
            padding-bottom: 0;
          }
        }
      }

      .card-content {
        padding-top: 0;

        @include media(">=tablet") {
          padding: 0;
          flex-grow: 1;
        }
      }

      .meta {
        display: block;
        margin-bottom: rem-calc(17);

        @include media(">=tablet") {
          margin-bottom: rem-calc(15);
        }

        .date {
          padding-left: 0;
          color: #757575;
        }
      }

      .category-label {
        display: none;
      }

      h3 {
        margin-bottom: 0.1765em; // 3px
        font-size: rem-calc(27);
        font-weight: 600;
        line-height: 1.3;

        @include media(">=tablet") {
          margin-bottom: 0.2727em; // 6px
        }
      }

      p {
        font-size: rem-calc(18);
        font-weight: normal;
        line-height: 1.45;
        color: #757575;
      }
    }

    &.is-small {
      font-size: 0.7647em; // 13px
      line-height: 1.6923; // 22px

      h3 {
        font-size: 1.3077em; // 17px
        line-height: 1.4118; // 24px;
        font-weight: $weight-semibold;
        margin-bottom: 0.1765em; // 3px
      }
    }
  }

  &-hero {
    &.promo-flexible {
      .content {
        p {
          max-width: 100% !important;
        }
      }
    }

    overflow: visible;

    @include media(">=tablet") {
      flex-direction: row-reverse;
      border-radius: $radius-xlarge;
    }

    .card-image {
      border-radius: $radius-large $radius-large 0 0;
      overflow: hidden;

      @include media("<=tablet") {
        border-radius: 0;
      }

      @include media(">=tablet") {
        width: 50%;
        flex-shrink: 0;
        display: flex;
        border-radius: $radius-large $radius-xlarge $radius-xlarge $radius-large;
      }

      .image {
        @include media(">=tablet") {
          padding-top: 0;
          width: 100%;
          min-height: rem-calc(320);
        }

        @include media(">=desktop") {
          min-height: rem-calc(420);
        }
      }
    }

    .card-content {
      padding: rem-calc(28 28 32);

      @include media(">=tablet") {
        width: 50%;
        flex-grow: 1;
        min-height: rem-calc(270);
      }

      @include media(">=desktop") {
        min-height: rem-calc(370);
        padding: rem-calc(44 46 44);
        padding-top: rem-calc(68) !important;
      }
    }

    .content {
      font-size: 0.9412em; // 16px
      line-height: 1.375; // 22px
      letter-spacing: -0.0312em; // -.5px

      @include media(">=tablet") {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      @include media(">=desktop") {
        font-size: 1.0588em; // 18px
        line-height: 1.3333; // 24px
        letter-spacing: -0.0389em; // -.7px
      }

      &:not(:last-child) {
        margin-bottom: rem-calc(17);
      }

      h2 {
        font-size: 1.375em; // 22px
        line-height: 1.1818; // 26px

        @include media(">=desktop") {
          font-size: 2.4444em; // 44px
          line-height: 1.0909; // 48px
          letter-spacing: -0.0159em; // -.7px
          margin-bottom: 15px;
        }
      }

      p {
        max-width: rem-calc(400);
      }
    }

    .made-by {
      @include media(">=tablet") {
        flex-shrink: 0;
      }
    }

    &.full-width {
      @include media(">=tablet") {
        border-radius: 0;
        margin-left: rem-calc(-20);
        margin-right: rem-calc(-20);
        padding-left: rem-calc(20);
        padding-right: rem-calc(20);
        width: calc(100% + 40px);
        max-width: none;
        margin-top: rem-calc(15);
      }

      @include media(">=desktop") {
        margin-left: calc((100vw - 944px) / -2);
        margin-right: calc((100vw - 944px) / -2);
        padding-left: calc((100vw - 944px) / 2);
        padding-right: calc((100vw - 944px) / 2);
        width: 100vw;
        margin-top: rem-calc(20);
      }

      @include media(">=widescreen") {
        margin-left: calc((100vw - 1136px) / -2);
        margin-right: calc((100vw - 1136px) / -2);
        padding-left: calc((100vw - 1136px) / 2);
        padding-right: calc((100vw - 1136px) / 2);
        margin-top: rem-calc(11);
      }

      @include media(">=fullhd") {
        margin-left: calc((100vw - 1200px) / -2);
        margin-right: calc((100vw - 1200px) / -2);
        padding-left: calc((100vw - 1200px) / 2);
        padding-right: calc((100vw - 1200px) / 2);
      }

      .card-hero-section & {
        @include media("<tablet") {
          margin-bottom: rem-calc(48);
        }
      }

      .card-image {
        @include media(">=tablet") {
          border-radius: $radius-large 0 0 $radius-large;
          margin: rem-calc(-30 -20 -30 0);
          width: calc(50% + 20px);
        }

        @include media(">=desktop") {
          margin: rem-calc(-45) calc((100vw - 944px) / -2) rem-calc(-45) 0;
          width: 50vw;
        }

        @include media(">=widescreen") {
          margin-right: calc((100vw - 1136px) / -2);
        }

        @include media(">=fullhd") {
          margin-right: calc((100vw - 1200px) / -2);
        }
      }

      .card-content {
        @include media(">=tablet") {
          padding-left: 0;
        }

        @include media(">=desktop") {
          padding-bottom: rem-calc(24);
          padding-top: rem-calc(54) !important;
        }
      }
    }

    &.full-width-variant {
      @include media(">=tablet") {
        border-radius: 0;
        margin-left: rem-calc(-20);
        margin-right: rem-calc(-20);
        padding-left: rem-calc(20);
        padding-right: rem-calc(20);
        width: calc(100% + 40px);
        max-width: none;
        margin-top: rem-calc(15);
      }

      @include media(">=desktop") {
        margin-left: calc((100vw - 944px) / -2);
        margin-right: calc((100vw - 944px) / -2);
        //padding-left: calc((100vw - 944px) / 2);
        //padding-right: calc((100vw - 944px) / 2);
        width: 100vw;
        margin-top: rem-calc(20);
      }

      @include media(">=widescreen") {
        margin-left: calc((100vw - 1136px) / -2);
        margin-right: calc((100vw - 1136px) / -2);
        padding-left: calc((100vw - 1136px) / 2);
        padding-right: calc((100vw - 1136px) / 2);
        margin-top: rem-calc(11);
      }

      @include media(">=fullhd") {
        margin-left: calc((100vw - 1200px) / -2);
        margin-right: calc((100vw - 1200px) / -2);
        padding-left: calc((100vw - 1200px) / 2);
        padding-right: calc((100vw - 1200px) / 2);
      }

      .card-hero-section & {
        @include media("<tablet") {
          margin-bottom: rem-calc(48);
        }
      }

      .card-image {
        @include media(">=tablet") {
          border-radius: 0 $radius-large $radius-large 0;
          margin: rem-calc(-30 -20 -30 -30);
          width: calc(50% + 20px);
        }

        @include media(">=desktop") {
          margin: rem-calc(-45) calc((100vw - 944px) / -2) rem-calc(-45) 50;
          width: 50vw;
        }

        @include media(">=widescreen") {
          margin-left: calc((100vw - 1136px) / -2);
        }

        @include media(">=fullhd") {
          margin-left: calc((100vw - 1200px) / -2);
        }
      }

      .card-content {
        @include media(">=tablet") {
          padding-left: rem-calc(100) !important;
        }

        @include media(">=desktop") {
          padding-bottom: rem-calc(24);
          padding-top: rem-calc(54) !important;
        }
      }
    }

    &:not(.full-width) {
      .card-hero-section & {
        @include media(">=tablet") {
          margin-bottom: rem-calc(-34);
        }

        @include media(">=desktop") {
          margin-bottom: rem-calc(-46);
        }
      }
    }
  }

  &-contact {
    line-height: 1.5294; // 26px
    font-weight: $weight-normal;
    // position: fixed;
    right: 0;
    // bottom: 25px;

    width: 260px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 rem-calc(10) rem-calc(20) rgba($black, 0.1);
    z-index: 10;
    height: auto;

    &.closed-mode {
      width: rem-calc(50);
    }

    @include media("<tablet") {
      font-size: rem-calc(14);
      max-width: rem-calc(200);
    }

    .contacts-list {
      li {
        word-break: break-word;

        @include media("<tablet") {
          padding-left: rem-calc(23) !important;

          .icon {
            top: rem-calc(3);
            font-size: 1.025rem;
          }
        }
      }
    }

    // @include media("<xl-fullhd") {
    // 	top: 70%;
    // }

    .card-content {
      padding: 1.375rem;

      @include media("<tablet") {
        padding: rem-calc(9.6 0 0 12);
      }

      .toggle-arrow {
        span.icon:hover {
          svg {
            path {
              stroke: $pink-dark;
            }
          }
        }
      }
    }

    ul {
      font-weight: $weight-normal;
    }

    .category-label {
      display: block;
      margin-bottom: rem-calc(2);
    }
  }

  &-author {
    font-size: 0.7647em; // 13px
    line-height: 1.7; // 22px
    font-weight: $weight-medium;
    letter-spacing: normal;

    .card-content {
      padding-top: rem-calc(30);
      padding-bottom: rem-calc(22);

      &:first-child {
        flex-grow: 0;
      }

      +.card-content {
        padding-top: rem-calc(25);
        padding-bottom: rem-calc(28);
      }
    }

    h5,
    .h5 {
      font-size: 1.5385em; // 20px
      line-height: 1.2;
      font-weight: $weight-semibold;
    }

    h6,
    .h6 {
      font-size: 1.1538em; // 15px
      line-height: 1.2;
      letter-spacing: -0.02em; // -.3px
      margin-bottom: 0.3333em; // 5px
    }

    .author-title {

      h6,
      .h6 {
        display: inline-block;
        vertical-align: middle;
        margin-right: rem-calc(13);
      }

      .social-networks {
        display: inline-block;
        vertical-align: middle;
        padding-bottom: rem-calc(3);

        li {
          display: inline;
          margin: 0;

          &:not(:last-child) {
            margin-right: rem-calc(4);
          }
        }
      }
    }

    .content-wrap {
      @include media("<phone") {
        display: block;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    .photo-holder {
      @include size(rem-calc(104));
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin: 0 auto rem-calc(40);

      @include media(">=phone") {
        margin: rem-calc(0 18 22 0);
      }

      .icon {
        @include size(auto);
        font-size: rem-calc(64);
        line-height: 1;
        font-weight: $weight-normal;
      }

      img {
        @include size(100%, 120%);
        border-radius: 10%;
        object-fit: cover;
        object-position: 50% 25%;
        margin-top: 20%;
      }
    }

    .text-holder {
      flex-grow: 1;
    }
  }

  height: 100%;
}

.search-sort-card {
  margin-bottom: rem-calc(15); // 15px
  height: auto;

  &.filter-card {
    @include media("<desktop") {
      margin-bottom: rem-calc(44);
    }
  }

  .card-content {
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);

    .search-sort-form {
      .control {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
        height: 100%;
      }
    }
  }
}

.card-title {
  padding: rem-calc(0 30 10 0);
  position: relative;

  h5,
  .h5 {
    margin-bottom: 0;
  }

  .icon-social-media {
    position: absolute;
    right: -2px;
    top: 0;

    .icon {
      @include size(auto);
      font-size: rem-calc(28);
      line-height: 1;
    }
  }
}

.icon-social-media {
  &[class*="has-text-"] {
    &:not(.link-more):not(.button) {
      &:hover {
        color: $link !important;
      }
    }
  }
}

.news-cards-container {
  max-height: rem-calc(220);
  overflow: auto;
  padding-right: rem-calc(10);

  @include media(">=tablet") {
    max-height: rem-calc(270);
  }

  @include media("<tablet") {
    overflow: auto;
  }
}

.news-articles {
  padding-bottom: rem-calc(47);
}

.padding-card-horizontal-none {
  .card-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.block_smartbrief,
.block_stat_chart,
.block_featured_text {
  .card {
    &.is-shadowless {
      &.is-outlined-white {
        &.has-background-white {
          border-radius: 0;

          .card-content {
            padding: 0;
            border-radius: 0;
          }
        }
      }
    }
  }
}

section {
  &.card {
    &.is-shadowless {
      &.is-outlined-white {
        &.has-background-white {
          .card-content {
            padding: 0;
            border-radius: 0;
          }
        }
      }
    }
  }
}

.side-column {
  &.block_featured_text {
    &:not(.is-pulled-left) {
      &:not(.is-pulled-right) {
        .card {
          margin-bottom: rem-calc(20);
        }
      }
    }
  }
}

.block_paragraph,
.block_richtext {
  .side-column {
    figure.image {
      figcaption.made-by {
        margin-bottom: rem-calc(25);
      }
    }
  }
}

@keyframes expand-floating-box {
  0% {
    width: rem-calc(62.5);
    height: rem-calc(170);
  }

  25% {
    width: rem-calc(125);
    height: rem-calc(170);
  }

  50% {
    width: rem-calc(190);
    height: rem-calc(170);
  }

  75% {
    width: rem-calc(230);
    height: rem-calc(170);
  }
}

@keyframes shrink-floating-box {
  0% {
    width: rem-calc(190);
  }

  25% {
    width: rem-calc(125);
  }

  50% {
    width: rem-calc(80);
  }

  75% {
    width: rem-calc(62.5);
  }
}

.exapand-effect {
  animation: expand-floating-box 0.15s;
}

.shrink-effect {
  animation: shrink-floating-box 0.15s;
}

section#fixedArea {
  flex-direction: column;
  display: flex;
  gap: 20px;
  align-items: flex-end;
  position: fixed;
  right: 0;
  bottom: 20px;
  z-index: 40;

  @media (max-width: 767px) {
    & {
      bottom: 88px;
    }
  }
}

.second-card {
  $card: &;
  display: flex;
  flex-direction: column;
  box-shadow: 0 12px 60px #00000021;
  width: 100%;
  height: 100%;

  .content-wrap {
    display: flex;
    gap: 13px;

    .icon-holder {
      width: 66px;
    }

    .text-holder {
      width: 1px;
      flex-grow: 1;
    }
  }

  &-image {
    overflow: hidden;

    img {
      transition: transform 0.4s ease;
    }

    &.onhover-image {
      &:hover {
        img {
          transform: scale(1.15);
        }
      }
    }
  }

  @for $i from 1 through 5 {

    @at-root .column.is-#{$i} & .second-card-content .columns,
    .column.is-#{$i}-desktop & .second-card-content .columns {
      display: block;

      .column {
        width: 100%;
      }
    }
  }

  .chart-holder {
    margin-bottom: 20px;
  }

  .partner-logo {
    margin-bottom: 1.5em;
  }

  .meta {
    display: flex;
    gap: 1.5em;
    margin-bottom: 1.5em;
    justify-content: space-between;

    .date {
      white-space: nowrap;
    }
  }

  .second-card-image {
    .image {
      aspect-ratio: 520 / 280;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &.is-horizontal:not(.second-card-news) {
    display: grid;
    --col: 2;

    @include media(">=tablet") {
      gap: 40px;
      grid-template-columns: repeat(var(--col), 1fr);

      &.hasTwoColumns {
        --col: 3;
        .second-card-content {
          grid-column: span 2 / span 2;
        }

        .content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap:0 80px;
          .second-card-title, .link {
            grid-column: span 2 / span 2;
          }
        }
      }

      &:not(.is-invert) .second-card-content {
        padding-left: 0;
      }

      &:is(.is-invert) .second-card-content {
        padding-right: 0;
        order: -1;
      }
    }

    .second-card-image {
      .image {
        position: relative;
        height: 100%;
        width: 100%;
      }
    }

    &.is-full-width {
      --col: 3;

      @include media(">=tablet") {
        .second-card-content {
          grid-column: span 2 / span 2;
        }
      }

      .second-card-image {
        .image {
          padding-top: 50%;
        }
      }
    }
  }

  &.card-newsletter {
    .second-card-content {
      display: flex;
      flex-direction: column;
      padding: 34px 24px 30px;
      gap: 24px;
      justify-content: space-between;

      @include media(">=tablet") {
        gap: 30px;
        padding: 48px 36px 40px;
      }

      &>.content {
        ::-webkit-scrollbar {
          width: 10px;
        }

        ::-webkit-scrollbar-track {
          background-color: transparent;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #a1c2c1;
        }

        ::-webkit-scrollbar-thumb:hover {
          background-color: #555;
        }

        margin: 0;
        flex-grow: 1;
        max-height: rem-calc(268);

        @include media(">=tablet") {
          max-height: rem-calc(400);
        }

        h3 {
          font-size: rem-calc(22);
          line-height: 1.095;
          padding-right: 0;
          letter-spacing: normal;
          padding: 0;

          @include media(">=tablet") {
            font-size: rem-calc(28);
            line-height: 1.3;
          }

          @include media(">=desktop") {
            font-size: rem-calc(32.65);
          }
        }

        .news-cards-container {
          max-height: none;

          .card {
            height: auto;
          }
        }
      }

      .subscribe-form {
        .input {
          font-size: rem-calc(13.9) !important;
          line-height: 1.733 !important;
          padding: 12px 103px 12px 14px !important;

          @include media(">=desktop") {
            font-size: rem-calc(21.09) !important;
            padding: 18px 158px 18px 22px !important;
          }
        }

        .button {
          font-size: rem-calc(13.18) !important;
          line-height: 1.827 !important;
          padding: rem-calc(3.71) rem-calc(16.67) !important;

          @include media(">=desktop") {
            font-size: rem-calc(20) !important;
            padding: rem-calc(5.62) rem-calc(25.3) !important;
          }
        }
      }
    }
  }

  .second-card-content {
    padding: 33px 24px;

    position: relative;
    flex-grow: 1;

    @include media(">=tablet") {
      padding: rem-calc(50 36 34 36);
    }

    & .full-width {
      padding: 25px 20px;

      @include media(">=tablet") {
        padding: 40px 20px;
      }
    }

    :is(h1, h2, h3, h4, h5, h6) {
      font-weight: 600;
      line-height: 1.4;
    }

    :is(h2, h3, h4) {
      font-size: rem-calc(22);
      line-height: 1.3;
      font-weight: 600;
      margin-bottom: rem-calc(11);

      @include media(">=tablet") {
        font-size: rem-calc(28);
        margin-bottom: rem-calc(16);
      }

      @include media(">=desktop") {
        font-size: rem-calc(32.65);
      }
    }

    .content {
      display: flex;
      flex-direction: column;

      font-size: rem-calc(15);
      line-height: 1.53;
      font-weight: 400;
      letter-spacing: -0.42px;

      @include media(">=tablet") {
        font-size: rem-calc(17);
        letter-spacing: -0.71px;
      }

      p {
        color: #6A6A6A !important;
      }

      p:not(:last-child),
      .content p:not(:last-child) {
        margin-bottom: rem-calc(11);

        @include media(">=tablet") {
          margin-bottom: rem-calc(16);
        }
      }

      ul:not(.social-networks) {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      ul>li,
      .content ul>li {
        position: relative;
        font-weight: 500;
        line-height: 1.4;
        color: $blue !important;

        @include media(">=tablet") {
          line-height: 1.5865;
        }
      }

      ul:not(.downloads-list, .social-networks)>li,
      .content ul:not(.downloads-list)>li {
        padding-left: rem-calc(18);

        @include media(">=tablet") {
          padding-left: rem-calc(28);
        }
      }

      ul>li+li,
      .content ul>li+li {
        margin-top: rem-calc(2);
      }

      .downloads-list {
        .icon {
          top: rem-calc(8);
        }
      }

      .button {
        align-self: flex-start;
      }
    }

    .percents-block {
      padding: 0;

      .percents-row {
        align-items: center;

        &.is-vertical {
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
        }
      }
    }

    .social-media {
      align-items: center;

      .title-social-media {
        margin-right: 10px;
      }

      .social-networks {
        gap: 10px;

        .icon {
          font-size: 26px;
        }
      }
    }

    .card.card-news {
      .card-content {
        .content {
          gap: 5px;

          .meta {
            margin: 0;
          }

          h4 a {
            font-size: rem-calc(14);
            font-weight: 500;
            line-height: 1.4;
            letter-spacing: rem-calc(-0.4631);
            display: block;

            @include media(">=desktop") {
              font-size: rem-calc(20);
              line-height: 1.4;
              letter-spacing: rem-calc(-0.7028);
            }
          }
        }
      }

      .date {
        color: #757575 !important;
        font-size: rem-calc(13);
        font-weight: 400;
        line-height: 1.38;
        letter-spacing: rem-calc(-0.327);

        @include media(">=tablet") {
          font-size: rem-calc(17);
          line-height: 1.605;
          letter-spacing: rem-calc(-0.4962);
        }
      }

      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }

    .button {
      border-radius: 3px;
      height: auto !important;
      font-size: 20px !important;
      padding: 0.565rem 1.5rem !important;

      @media (max-width: 767px) {
        font-size: 16px !important;
      }

      &:is([type="submit"]):not(.is-outlined) {
        background: $lime;
        color: $blue-darkest !important;
      }

      &:hover {
        background-color: $lime-dark;
        color: $blue-darkest !important;
      }
    }

    .input {
      border-radius: 0;
      height: auto !important;
      padding: 1rem 1.5rem !important;
      font-size: 20px !important;

      @media (max-width: 767px) {
        font-size: 16px !important;
      }
    }
  }

  &.full-width {
    .second-card-content {
      padding: 25px 20px;

      @include media(">=tablet") {
        padding: 40px 20px;
      }
    }
  }

  &.second-card-news {
    &.is-horizontal {
      flex-direction: row;
      gap: rem-calc(32);

      .card-image {
        overflow: hidden;
        z-index: 1;
        flex-shrink: 0;
        width: 27.5%;

        img {
          border-radius: 0;
        }
      }
    }

    &:not(.is-horizontal) {
      .second-card-image {
        .image {
          position: relative;
          aspect-ratio: 522/365;
          box-sizing: border-box;
        }
      }

      .second-card-content {
        padding: 16px 20px 20px;
        position: relative;
        flex-grow: 1;

        @include media(">=tablet") {
          padding: 30px 29px 86px;
        }

        .meta {
          margin-bottom: rem-calc(10);

          @include media(">=tablet") {
            margin-bottom: rem-calc(13.5);
          }

          .date {
            font-size: 13px;
            font-weight: 400;
            line-height: 1.38;
            letter-spacing: rem-calc(-0.327);

            @include media(">=tablet") {
              font-size: 17px;
              line-height: 1.605;
              letter-spacing: rem-calc(-0.4962);
            }
          }
        }
      }

      .card-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.25;
        padding-right: 0;

        @include media(">=tablet") {
          font-size: 22px;
          letter-spacing: normal;
        }

        a {
          font-size: inherit;
          font-weight: inherit;
          line-height: inherit;
        }
      }
    }
  }

  &.second-chart {
    .second-card-content {
      padding: 0;
    }
  }

  &.second-contact {
    .second-card-content {
      padding: rem-calc(32 34);
    }

    h3 {
      font-size: rem-calc(25.6);
      line-height: 1.25;
      letter-spacing: 0;
      margin-bottom: rem-calc(24);
    }
  }

  &.has-background-grey,
  &.has-background-primary,
  &.has-background-primary-lighter,
  &.has-background-link,
  &.has-background-medium-blue,
  &.has-background-info,
  &.has-background-black,
  &.has-background-red,
  &.has-background-yellow,
  &.has-background-midnight-blue,
  &.has-background-intense-red,
  &.has-background-bright-orange,
  &.has-background-light-pink,
  &.has-background-dark-purple {
    .content {

      p,
      ul>li,
      .content ul>li,
      a:not(.button) {
        color: $white !important;
        opacity: 0.8;
      }
    }
  }


  .btn,
  .link-download {
    color: $link !important;

    * {
      color: $link !important;
    }
  }

  .downloads-list {
    li {
      padding: 0 !important;

      .icon {
        color: $link;
      }
    }
  }

  &.has-background-grey,
  &.has-background-primary,
  &.has-background-primary-lighter,
  &.has-background-link,
  &.has-background-medium-blue,
  &.has-background-info,
  &.has-background-black,
  &.has-background-red,
  &.has-background-yellow,
  &.has-background-midnight-blue,
  &.has-background-intense-red,
  &.has-background-bright-orange,
  &.has-background-light-pink,
  &.has-background-dark-purple {
    .btn {
      color: $white !important;

      * {
        color: $white !important;
      }
    }
  }

  &.has-background-green,
  &.has-background-lime-green {
    .btn {
      color: $blue-darkest !important;

      * {
        color: $blue-darkest !important;
      }
    }
  }

  // .{
  //   //#FFFFFF
  //   color: #000;
  // }
  // .{
  //   //#E8F0EF
  //   color: #000;
  // }

  // .{
  //   //#A7D1D7
  //   color: #000;
  // }
  // .{
  //   //#E8F5F8
  //   color: #000;
  // }
  // .{
  //   //#B5B5B5
  //   color: #000;
  // }
  // .{
  //   //#B3DAF3
  //   color: #000;
  // }
  // .{
  //   //#C1E5E9
  //   color: #000;
  // }
  &.twitter-feed {
    .link-account {
      color: $teal !important;
      font-size: 18px;

      &:hover {
        color: $pink !important;
      }
    }

    .icon-social-media {
      float: right;
      color: $teal !important;

      &:hover {
        color: $pink !important;
      }

      i {
        width: 32px !important;
        height: 32px !important;
        font-size: 32px !important;
      }

    }

    .second-card-title {
      margin-bottom: 20px;

      h5 {
        font-size: 30px;
        margin: 0;
      }
    }

    .tweets-posts {
      h6 {
        font-size: 24px;
      }

      .post:not(:last-child) {
        margin-bottom: 20px;
      }

      time {
        font-size: 18px;
        color: #757575 !important;
        font-weight: 400 !important;
      }
    }

    .link-social {
      font-size: 18px;

      i {
        width: 23px !important;
        height: 23px !important;
        font-size: 23px !important;
      }
    }
  }
}

.onhover {
  transition: box-shdaow, 0.4s ease;

  &.second-card {
    transition: background, box-shdaow, 0.4s ease;

    .content {

      *:is(h1, h2, h3, h4),
      *:is(p),
      *:is(.link-more),
      *:is(li) {
        transition: color 0.4s ease;
      }

    }
  }

  &:hover {
    box-shadow: 0px 12.17px 60.84px 0px #00000021 !important;

    &.second-card {
      background: #fff !important;

      .content {
        *:is(h1, h2, h3, h4) {
          color: $blue-darkest !important;
        }

        *:is(p) {
          color: #6A6A6A !important;
        }

        *:is(.link-more),
        &>a {
          color: $pink !important;

          * {
            color: $pink !important;
          }

        }

        *:is(li),
        *:is(li) * {
          color: $blue !important;
        }

        *:is(a):hover {
          color: $pink !important;

          * {
            color: $pink !important;
          }

        }
      }
    }

  }
}

.news-slider {
  .swiper-slide {
    height: auto;
  }
}