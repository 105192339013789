.container-column {
  word-break: break-word;

  &.has-background-white {
    &.is-outlined-white {
      .card-content {
        padding: 0 !important;
      }
    }
  }

  &:not(.has-background-white, .is-second) {
    border-radius: 15px;
  }

  .card-content {
    .link-view {
      min-width: rem-calc(100);
    }
  }

  &.is-second {
    h3 {
      font-weight: 600;
      font-size: rem-calc(22);
      line-height: 1.095;
      padding-right: 0;
      letter-spacing: normal;
      padding: 0;

      @include media(">=tablet") {
        font-size: rem-calc(28);
        line-height: 1.3;
      }

      @include media(">=desktop") {
        font-size: rem-calc(32.65);
      }
    }

    .card-content {
      .link-icon {
        font-size: rem-calc(13);
        line-height: 1.2;
        font-weight: 500;
        min-width: 0;
        align-self: flex-end;
        margin-top: 0;
        margin-bottom: rem-calc(12);
        color: $pink;

        .icon {
          @include animate(transform color, 0.15s, ease-out);
        }

        &:hover {
          color: $pink-dark !important;

          .icon {
            transform: translateX(2px);
          }
        }

        @include media(">=tablet") {
          font-size: rem-calc(15);
          margin-bottom: rem-calc(22);
        }

        @include media(">=desktop") {
          font-size: rem-calc(17.99);
          line-height: 1.4;
          min-width: rem-calc(100);
          margin-bottom: rem-calc(24);
        }
      }

      ul {
        line-height: 1.35;
      }
    }

    .custom-columns {
      gap: rem-calc(32);

      @include media(">=desktop") {
        gap: rem-calc(42);
      }
    }
  }
}

.custom-columns {
  word-break: break-word;

  .blog-segment {
    border-bottom: 1px dashed #585858;
    padding-bottom: 1em;

    &-not-bottom-border {
      border-bottom: none;

      @include media("<=desktop") {
        border-bottom: 1px dashed #585858;
        padding-bottom: 1em;
      }
    }

    &.initial-column {
      @include media("<=desktop") {
        padding-top: 1em;
      }
    }
  }

  @include media("<=desktop") {
    flex-wrap: wrap;
  }
}

.block_columns {
  .custom-columns {
    ul {
      li {
        a {
          color: $blue !important;

          &:hover {
            color: $pink-dark !important;
          }
        }
      }
    }
  }
}
