.videoWrapper, .second-videoWrapper {
  position: relative;
  border-radius: 0;
  overflow: hidden;
	
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
	z-index: 1;
    width: 100%;
    height: 100%;
		background-color: black;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 */
  }
}

/***** Temporary disabled
.block_video_embed {
	.videoWrapper {
		background-color: $black;
	}
}
*/


//video {
//  /* override other styles to make responsive */
//  width: 100%    !important;
//  height: auto   !important;
//  border-radius: 15px;
//}

/*.video-embed {
	iframe {
		border-radius: 15px;
		background-color: $black;
	}
}

video {
	background-color: $black;
}

#player, .player {
	border-radius: 15px !important;

	.vp-video-wrapper {
		border-radius: 15px !important;

		.vp-video {
			border-radius: 15px !important;
		}
	}
}

iframe {
.player {
	background-color: red;
	.vp-video-wrapper{
		border-radius: 15px !important;
		.vp-preview {
			border-radius: 15px !important;
		}
	}
}
}

.video-embed, .video-upload {
	.vimeo-video {
		position: absolute;
		top: 0;
		left: 0;
	}

	&__sm {
		width: 100%;
		height: auto;
	}

	&__md {
		width: 100%;
		height: auto;
	}

	&__lg {
		width: 100%;
		height: auto;
	}
}

.video-block {
	border-radius: $radius-large;
	overflow: hidden;

	&:not(:last-child) {
		margin-bottom: rem-calc(20);
	}

	figure {
		margin-left: 0;
		margin-right: 0;
	}
}

.btn-play {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	&:hover {

		&:before {
			background: $white;
		}
	}

	img {
		object-fit: cover;
		object-position: 50% 50%;
		z-index: 1;
	}

	&:before {
		@include animate(background, .15s, ease-out);
		@include size(rem-calc(74));
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		background: rgba($white, .6);
		z-index: 2;
	}

	&:after {
		@include triangle(rem-calc(36) rem-calc(24), $link, right);
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		margin-left: rem-calc(4);
		z-index: 3;
	}
}
*/
