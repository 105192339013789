.subscribe-form {
	.card & {
		margin-top: auto;
	}

	label,
	.label {
		font-size: rem-calc(18);
		line-height: 1.5438;
		letter-spacing: rem-calc(-0.51);
		margin-bottom: rem-calc(10);

		@include media(">=desktop") {
			font-size: rem-calc(30);
			line-height: 1.4056;
			letter-spacing: rem-calc(-0.7731);
		}
	}

	.input {
		&:not(.is-small):not(.is-medium):not(.is-large) {
			font-size: rem-calc(15); // 15px / 26px
			height: 3.3333em; // 50px
			padding-top: 0.7333em; // 11px
			padding-bottom: 0.7333em; // 11px
			padding-left: 1em;
			padding-right: rem-calc(110);

			&::placeholder {
				font-style: italic;
			}
		}
	}

	button[type="submit"],
	input[type="submit"] {
		position: absolute;
		right: rem-calc(9);
		top: 50%;
		transform: translateY(-50%);
		padding-left: 0.875em !important; // 14px
		padding-right: 0.875em !important; // 14px
	}
}

.block_smartbrief {
	.card.card-newsletter {
		.card-content {
			.content {
				.suscribe-form {
					input[type="submit"] {
						color: $white !important;
					}
				}
			}
		}
	}
}
